import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  Divider,
  Grid,
  IconButton,
  MenuItem,
  Paper,
  Snackbar,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { GetKeyMetrics } from "../api/GetKeyMetrics";
import { GetKeyMetricValues } from "../api/GetKeyMetricValues";
import { GetReportingEntities } from "../api/GetReportingEntities";
import { PostKeyMetricValues } from "../api/PostKeyMetricValues";
import {
  KeyMetric,
  KeyMetricValue,
  ReportingEntity,
} from "../types/KeyMetrics";
import { toast } from "react-toastify";
import { transformQuarter } from "./LiquidityForm";
import { Close, Search } from "@mui/icons-material";
import DashboardChart from "./DashboardChart";
import { sortKeyMetricValuesByPeriod } from "./UpdateKeyMetrics";

type KeyMetricFormProps = {
  mode: "regular" | "modal";
  keyMetricValue?: KeyMetricValue | undefined;
  onSubmit?: (values: KeyMetricValue[]) => void;
  onClose?: () => void;
};

export function sortPeriods(periods: string[]) {
  return periods.sort((a, b) => {
    // Extract the quarter and year from periodA and periodB
    const quarterA = parseInt(a[1]); // Get the quarter part (Q1 -> 1)
    const yearA = parseInt(a.slice(2)); // Get the year part (23 -> 2023)

    const quarterB = parseInt(b[1]); // Get the quarter part (Q1 -> 1)
    const yearB = parseInt(b.slice(2)); // Get the year part (23 -> 2023)

    // First, compare by year
    if (yearA !== yearB) {
      return yearB - yearA;
    }

    // If the year is the same, compare by quarter
    return quarterB - quarterA;
  });
}

export const generateReportingPeriods = (): string[] => {
  const reportingPeriods: string[] = [];

  const today = new Date();
  const currentYear = today.getFullYear();
  const currentMonth = today.getMonth() + 1; // Months are zero-indexed, so we add 1.

  // Determine the current quarter
  let currentQuarter = Math.ceil(currentMonth / 3);

  // If we're currently in Q3, we want to limit the array to Q2
  let endYear = currentYear;
  let endQuarter = currentQuarter - 1;

  // If the current quarter is Q1, the preceding quarter is Q4 of the previous year
  if (endQuarter === 0) {
    endQuarter = 4;
    endYear = currentYear - 1;
  }

  // Generate reporting periods from Q121 to the preceding quarter
  for (let year = 2021; year <= endYear; year++) {
    for (let quarter = 1; quarter <= 4; quarter++) {
      // Stop adding periods if we reach the endQuarter and endYear
      if (year === endYear && quarter > endQuarter) {
        break;
      }
      reportingPeriods.push(`Q${quarter}${year.toString().slice(2)}`);
    }
  }

  return sortPeriods(reportingPeriods);
};

const KeyMetricForm = ({
  mode,
  keyMetricValue,
  onSubmit,
  onClose,
}: KeyMetricFormProps) => {
  const [reportingEntities, setReportingEntities] = useState<ReportingEntity[]>(
    []
  );
  const [selectedEntity, setSelectedEntity] = useState<number | null>(
    keyMetricValue?.keyMetric.reportingEntityId ?? null
  );
  const [reportingPeriods, setReportingPeriods] = useState<string[]>(
    generateReportingPeriods()
  );
  const [selectedPeriod, setSelectedPeriod] = useState<string>(
    keyMetricValue?.reportingPeriod ?? ""
  );
  const [keyMetrics, setKeyMetrics] = useState<KeyMetric[]>([]);
  const [metricValues, setMetricValues] = useState<{ [key: number]: number }>(
    {}
  );
  const [isLoading, setIsLoading] = useState(false);
  const [selectedKeyMetric, setSelectedKeyMetric] = useState<KeyMetric | null>(
    null
  );
  const [openPreviewModal, setOpenPreviewModal] = useState(false);
  const [data, setData] = useState<{
    name: string;
    values: number[];
    labels: string[];
  }>();

  const [loadingValues, setLoadingValues] = useState<boolean>(false);

  // Fetch reporting entities on component mount
  useEffect(() => {
    const fetchReportingEntities = async () => {
      try {
        const response = await GetReportingEntities();
        setReportingEntities(response);
      } catch (error) {
        console.error("Error fetching reporting entities", error);
      }
    };

    fetchReportingEntities();
    if (keyMetricValue) {
      fetchMetricValues();
    }
  }, []);

  // Fetch key metrics when a reporting entity is selected
  useEffect(() => {
    const fetchKeyMetrics = async () => {
      if (selectedEntity) {
        try {
          const response = await GetKeyMetrics({
            reportingEntityId: selectedEntity,
          });
          setKeyMetrics(response);
          setMetricValues({}); // Reset metric values when the entity changes
        } catch (error) {
          console.error("Error fetching key metrics", error);
        }
      }
    };

    fetchKeyMetrics();
  }, [selectedEntity]);

  const mapKeyValues = (values: KeyMetricValue[]) => {
    const valuesMap: { [key: number]: number } = {};
    values.forEach((kv) => {
      valuesMap[kv.keyMetricId] = kv.amount;
    });
    return valuesMap;
  };

  const fetchMetricValues = async () => {
    if (selectedEntity && selectedPeriod) {
      try {
        const response = await GetKeyMetricValues({
          reportingEntityId: selectedEntity,
          reportingPeriod: selectedPeriod,
        });

        // Convert the fetched data to a map of keyMetricId -> amount
        const valuesMap = mapKeyValues(response);
        setMetricValues(valuesMap);
      } catch (error) {
        console.error("Error fetching key metric values", error);
      }
    }
  };

  // Fetch key metric values when both entity and period are selected
  useEffect(() => {
    fetchMetricValues();
  }, [selectedEntity, selectedPeriod]);

  const handleEntityChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedEntity(Number(event.target.value));
  };

  const handlePeriodChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedPeriod(event.target.value);
  };

  const handleMetricValueChange = (metricId: number, value: string) => {
    setMetricValues({
      ...metricValues,
      [metricId]: Number(value),
    });
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setIsLoading(true);

    try {
      const data = keyMetrics.map((metric) => ({
        reportingPeriod: selectedPeriod,
        keyMetricId: metric.id,
        amount: metricValues[metric.id] || 0,
      }));

      let result = await PostKeyMetricValues(data);

      onSubmit && onSubmit(result);
      toast.success("Successfully updated record!");
    } catch (error) {
      console.error("Error submitting data", error);
      alert("Failed to submit data");
    } finally {
      setIsLoading(false);
    }
  };

  const handleReset = () => {
    setSelectedEntity(null);
    setSelectedPeriod("");
    setMetricValues({});
    setKeyMetrics([]);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (selectedKeyMetric) {
          if (!selectedEntity) return;
          setLoadingValues(true);
          const response = await GetKeyMetricValues({
            reportingEntityId: selectedEntity,
          });

          const metricValues = response.filter(
            (item) => item.keyMetricId === selectedKeyMetric?.id
          );
          const sortedData = sortKeyMetricValuesByPeriod(metricValues, true);
          const labels = sortedData.map((item) => item.reportingPeriod);
          const data = {
            name: sortedData[0]?.keyMetric.name ?? "",
            values: sortedData.map((item) => item.amount).slice(-12),
            labels: labels.slice(-12),
          };
          setData(data);
        }
      } catch (error) {
        console.error("Error fetching key metric values", error);
      } finally {
        setLoadingValues(false);
      }
    };
    fetchData();
  }, [selectedKeyMetric]);

  return (
    <Grid component={Paper} item container xs={12} sx={{ padding: 2 }}>
      <Box component="form" onSubmit={handleSubmit} sx={{ width: "100%" }}>
        <Grid item xs={12}>
          <Grid item xs={9}>
            <Typography variant="h6" gutterBottom>
              Nøkkeltall
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={3}></Grid>
        <Grid container xs={12} spacing={2} sx={{ mt: 1 }}>
          {/* Reporting Entity Dropdown */}
          <Grid item xs={12} sm={6}>
            <TextField
              size="small"
              select
              disabled={mode === "modal"}
              label="Reporting Entity"
              value={selectedEntity ?? ""}
              onChange={handleEntityChange}
              fullWidth
              SelectProps={{
                MenuProps: {
                  PaperProps: {
                    style: {
                      maxHeight: 300, // Limit the height of the dropdown (in pixels)
                    },
                  },
                },
              }}
            >
              {reportingEntities.map((entity) => (
                <MenuItem key={entity.entityId} value={entity.entityId}>
                  {entity.entityName}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          {/* Reporting Period Dropdown */}
          <Grid item xs={12} sm={6}>
            <TextField
              size="small"
              select
              label="Reporting Period"
              disabled={mode === "modal"}
              value={selectedPeriod}
              onChange={handlePeriodChange}
              fullWidth
              SelectProps={{
                MenuProps: {
                  PaperProps: {
                    style: {
                      maxHeight: 300, // Limit the height of the dropdown (in pixels)
                    },
                  },
                },
              }}
            >
              {reportingPeriods.map((period) => (
                <MenuItem key={period} value={period}>
                  {transformQuarter(period)}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item container spacing={3} xs={12}>
            {keyMetrics.map((metric) => (
              <Grid item container xs={12} sm={6} key={metric.id}>
                <Grid item xs={11}>
                  <TextField
                    size="small"
                    label={`${metric.name} (${metric.measurement ?? ""})`}
                    value={metricValues[metric.id] ?? ""}
                    sx={{ textAlign: "right" }}
                    onChange={(e) =>
                      handleMetricValueChange(metric.id, e.target.value)
                    }
                    fullWidth
                    type="number"
                  />
                </Grid>
                <Grid item container xs={1} justifyContent={"right"}>
                  <Tooltip title="Se historikk">
                    <IconButton
                      onClick={() => {
                        setSelectedKeyMetric(metric);
                        setOpenPreviewModal(true);
                      }}
                      size="small"
                      disabled={
                        selectedEntity === null || selectedPeriod === ""
                      }
                    >
                      <Search />
                    </IconButton>
                  </Tooltip>
                </Grid>
              </Grid>
            ))}
          </Grid>
          {/* Submit and Reset Buttons */}
          <Grid item xs={12}>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              disabled={isLoading}
            >
              Lagre
            </Button>
            {mode === "regular" && (
              <Button
                onClick={handleReset}
                variant="outlined"
                color="secondary"
                sx={{ ml: 2 }}
              >
                Reset
              </Button>
            )}
          </Grid>
        </Grid>
        <Dialog
          open={openPreviewModal}
          onClose={() => setOpenPreviewModal(false)}
          maxWidth="lg"
        >
          <DialogContent sx={{ padding: 0 }}>
            <IconButton
              size="large"
              onClick={() => setOpenPreviewModal(false)}
              sx={{
                position: "absolute",
                top: "2px",
                right: "2px",
                color: "white",
              }}
            >
              <Close />
            </IconButton>
            {loadingValues ? (
              <Grid
                item
                container
                xs={12}
                justifyContent={"center"}
                alignContent={"center"}
                sx={{
                  height: 500,
                  width: 800,
                  background: "linear-gradient(to bottom, #05445e, #2e8bc0)",
                  padding: 0,
                  margin: 0,
                }}
              >
                <CircularProgress size={150} sx={{ color: "white" }} />
              </Grid>
            ) : (
              data && (
                <DashboardChart
                  data={[data]}
                  labels={data?.labels}
                  title={data.name}
                  width={800}
                  height={500}
                />
              )
            )}
          </DialogContent>
        </Dialog>
      </Box>
    </Grid>
  );
};

export default KeyMetricForm;
