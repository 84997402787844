import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { useState } from "react";
import { TrialBalanceAccountDto } from "../api/GetTrialBalance";
import { Account, AccountingPeriod } from "../types/account";
import { formatNumberInMillions } from "../utils/formatting";
import { getDistinctObjectsByPropertyAndTransform } from "../utils/getDistinctObjects";
import { getRelevantPeriods } from "../utils/timePeriods";

type FinancialTableProps = {
  unit: string;
  accountingPeriods: AccountingPeriod[];
  accounts: Account[];
  highlightedPeriods: string[];
  data: TrialBalanceAccountDto[];
  currentPeriod: string;
};

const highligtColor = "#faf7f2";

export default function FinancialTable({
  unit,
  accountingPeriods,
  accounts,
  highlightedPeriods,
  data,
  currentPeriod,
}: FinancialTableProps) {
  const [selectedItem, setSelectedItem] = useState<Account | null>(null);
  const onClickRow = (item: Account) => {
    setSelectedItem((state) => (state === item ? null : item));
  };

  function getRelevantAccounts(account: Account) {
    let tbData = data.filter(
      (item) => item.financialStatementCaption === account.name
    );

    const accounts = getDistinctObjectsByPropertyAndTransform(
      tbData,
      "accountId",
      (c) => ({
        accountId: c.accountId,
        accountName: c.accountName,
        accountingPeriods: accountingPeriods.map((item) => {
          const periods = getRelevantPeriods(item.name);
          const amount = tbData
            .filter(
              (tbAcc) =>
                periods.includes(tbAcc.period.toString()) &&
                tbAcc.accountId === c.accountId
            )
            .reduce((value, item) => (value += item.amount), 0);
          return { name: item.name, value: amount };
        }),
        onlyContainsZeroes: true,
        total: 0,
      })
    );

    accounts.forEach((acc) => {
      let total = tbData
        .filter((tb) => tb.accountId === acc.accountId)
        .reduce((value, item) => (value += item.amount), 0);

      let onlyContainsZeroes = tbData
        .filter((tb) => tb.accountId === acc.accountId)
        .reduce((value, item) => (item.amount !== 0 ? false : true), true);

      acc.onlyContainsZeroes = onlyContainsZeroes;
      acc.total = total;
    });

    let sortedAccounts = accounts.filter((item) => !item.onlyContainsZeroes);

    sortedAccounts.sort((a, b) => {
      let bValue =
        b.accountingPeriods.find((item) => item.name === currentPeriod)
          ?.value ?? 0;
      let aValue =
        a.accountingPeriods.find((item) => item.name === currentPeriod)
          ?.value ?? 0;
      return bValue - aValue;
    });

    return sortedAccounts;
  }

  return (
    <TableContainer component={Paper} sx={{ padding: "12px" }}>
      <Table
        sx={{
          minWidth: 650,
          fontFamily: "Arial, sans-serif",
          tr: { fontFamily: "Poppins, sans-serif", fontSize: "0.9rem" },
        }}
      >
        <TableHead>
          <TableRow
            sx={{
              th: {
                fontWeight: "bold",
                fontFamily: "Poppins, sans-serif",
                fontSize: "0.8rem",
                padding: "10px",
              },
            }}
          >
            <TableCell>{unit}</TableCell>
            {accountingPeriods.map((item) => (
              <TableCell
                width={100}
                align="right"
                sx={{
                  fontWeight: highlightedPeriods.includes(item.name)
                    ? "bold"
                    : undefined,
                  backgroundColor: highlightedPeriods.includes(item.name)
                    ? highligtColor
                    : undefined,
                }}
              >
                {item.name}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {accounts.map((item) => (
            <>
              <TableRow
                onClick={() => onClickRow(item)}
                sx={{
                  "&:hover": { cursor: "pointer" },
                  td: {
                    fontWeight: item.highlight
                      ? "bold"
                      : selectedItem === item
                      ? "bold"
                      : undefined,
                    backgroundColor: item.highlight
                      ? highligtColor
                      : selectedItem === item
                      ? "lightblue"
                      : undefined,
                    fontFamily: "Poppins, sans-serif",
                    fontSize: "0.8rem",
                    padding: "10px",
                    borderBottom: "none",
                  },
                }}
              >
                <TableCell>{item.name}</TableCell>
                {item.accountingPeriods.map((item2, index) => (
                  <TableCell
                    align="right"
                    sx={{
                      fontWeight: highlightedPeriods.includes(
                        accountingPeriods[index].name
                      )
                        ? "bold"
                        : undefined,
                      backgroundColor: highlightedPeriods.includes(
                        accountingPeriods[index].name
                      )
                        ? highligtColor
                        : undefined,
                      //color: highlightedPeriods.includes(accountingPeriods[index].name) ? "white" : undefined,
                    }}
                  >
                    {formatNumberInMillions(item2.value)}
                  </TableCell>
                ))}
              </TableRow>
              {/* <Collapse
                in={selectedItem === item}
                timeout="auto"
                unmountOnExit
                sx={{ width: "100%" }}
              > */}

              {selectedItem &&
                selectedItem === item &&
                getRelevantAccounts(selectedItem).map((item) => (
                  <TableRow
                    sx={{
                      width: "100%",
                      td: {
                        fontWeight: selectedItem.highlight ? "bold" : undefined,
                        backgroundColor: selectedItem.highlight
                          ? highligtColor
                          : undefined,
                        fontFamily: "Poppins, sans-serif",
                        fontSize: "0.8rem",
                        padding: "10px",
                        borderBottom: "none",
                        fontStyle: "italic",
                      },
                    }}
                  >
                    <TableCell>
                      {"   " + item.accountId + " " + item.accountName}
                    </TableCell>
                    {item.accountingPeriods.map((item2, index) => (
                      <TableCell
                        align="right"
                        sx={{
                          fontWeight: highlightedPeriods.includes(
                            accountingPeriods[index].name
                          )
                            ? "bold"
                            : undefined,
                          backgroundColor: highlightedPeriods.includes(
                            accountingPeriods[index].name
                          )
                            ? highligtColor
                            : undefined,
                        }}
                      >
                        {selectedItem && formatNumberInMillions(item2.value)}
                      </TableCell>
                    ))}
                  </TableRow>
                ))}

              {/* </Collapse> */}
            </>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
