import { Typography } from "@mui/material";

type HeaderProps = {
  title: string;
};

export default function Header({ title }: HeaderProps) {
  return (
    <Typography sx={{ fontWeight: "bold" }} variant="button">
      {title}
    </Typography>
  );
}
