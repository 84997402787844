import moment from "moment";
import { DefaultAxiosDownloadTrigger, DefaultAxiosGetRequest, DefaultAxiosPostRequest } from "./ApiBase";

/* export type KeyMetricsProps = {
   periods: string[];
   values: number[];
   title: string;
   entity: string;
}; */

export interface PowerpointReportDto {
   entity: string;
   date: string;
   description: string;
   unit: "NOK" | "TNOK" | "MNOK";
   lineCharts: LineChartValuesDto[];
   tables: TableValuesDto[];

}

export interface LineChartValuesDto {
   periods: string[];
   values: number[];
   title: string;
   axisTitle: string;
   comments: string[];
}

export interface TableValuesDto {
   accountingPeriods: AccountingPeriod[];
   values: AccountingRow[];
   title: string;
   comments: string[];
}

export interface AccountingPeriod {
   name: string;
   highlight: boolean;
}

export interface AccountingRow {
   name: string;
   highlight: boolean;
   values: number[];
}

export interface ReportedValuesDto {
   periods: string[];
   values: number[];
   title: string;
   entity: string;
   axisTitle: string;
}

export interface PowerpointReportInbound {
   id: number;
   data: string;
}

export interface PowerpointReport {
   id: number;
   data: PowerpointReportDto;
}

export default async function DownloadKeyMetrics(values: ReportedValuesDto[]) {
   if (values.length === 0) return;
   //Show maximum of 12 periods in one chart
   values.forEach((v) => {
      v.values = v.values.splice(-12);
      v.periods = v.periods.splice(-12);
   });

   DefaultAxiosDownloadTrigger(`CreateKeyMetricsPresentation`, `Presentation`, values);
}

export async function DownloadGriegConnectPresentation(report: PowerpointReportDto) {
   if (report.lineCharts.length === 0 && report.tables.length === 0) return;
   //Show maximum of 12 periods in one chart
   /* values.forEach((v) => {
      v.values = v.values.splice(-12);
      v.periods = v.periods.splice(-12);
   }); */

   let result = await DefaultAxiosDownloadTrigger(`CreateGriegConnectPresentation`, `Presentation`, report);
   return result;
}

export async function GetPowerpointReports() {
   const result = await DefaultAxiosGetRequest<PowerpointReportInbound[], undefined>(`GetPresentationData`, undefined);
   const reports = result.data.map((r) => ({id: r.id, data: JSON.parse(r.data as string) as PowerpointReportDto }));
   return reports;
}

export async function SavePowerpointReport(report: PowerpointReport) {
   let parsedData = {
      id: report.id,
      data: JSON.stringify(report.data)
   };

   const result = await DefaultAxiosPostRequest<boolean, PowerpointReportInbound>(`SavePresentationData`, parsedData);
   return result.data;
}

export async function DeletePowerpointReport(report: PowerpointReport) {
   const result = await DefaultAxiosGetRequest<boolean, { id: number }>(`DeletePresentationData`, { id: report.id });
   return result.data;
}