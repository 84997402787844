import { KeyMetricValue } from "../types/KeyMetrics";
import { DefaultAxiosPostRequest } from "./ApiBase";

export interface PostKeyMetricValueDto {
    reportingPeriod: string;
    keyMetricId: number;
    amount: number;
}

export async function PostKeyMetricValues(values: PostKeyMetricValueDto[]): Promise<KeyMetricValue[]> {
    try {
       let result = await DefaultAxiosPostRequest<KeyMetricValue[], PostKeyMetricValueDto[]>(
          `PostKeyMetricValues`, values
       );
       return result.data;
    } catch (e) {
       throw e;
    }
 }