import { KeyMetric } from "../types/KeyMetrics";
import { DefaultAxiosGetRequest } from "./ApiBase";

type KeyMetricsQuery = {
    reportingEntityId: number;
}

export async function GetKeyMetrics(parameters?: KeyMetricsQuery): Promise<KeyMetric[]> {
    try {
       let result = await DefaultAxiosGetRequest<KeyMetric[], KeyMetricsQuery | undefined>(
          `GetKeyMetrics`, parameters ? parameters: undefined
          
       );
       return result.data;
    } catch (e) {
       throw e;
    }
 }