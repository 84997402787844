import { MoreVert } from "@mui/icons-material";
import { Card, CardContent, CardHeader, Grid, IconButton } from "@mui/material";
import "chart.js/auto";
import React from "react";

type DashboardCardProps = {
   size: "small" | "medium" | "large";
   component: React.ReactNode;
   title: string;
};
export default function DashboardCard({ size, component, title }: DashboardCardProps) {
   return (
      <Grid item container xs={size === "small" ? 6 : 12} sm={size === "large" ? 12 : size === "medium" ? 6 : 4}>
         <Grid item xs={12}>
            <Card>
               <CardHeader
                  sx={{
                     color: "primary.contrastText",
                     backgroundColor: "primary.main",
                  }}
                  titleTypographyProps={{
                     fontSize: 13,
                     fontWeight: "bold",
                  }}
                  title={title}
                  action={
                     <IconButton size="small">
                        <MoreVert sx={{ color: "primary.contrastText" }} />
                     </IconButton>
                  }
               />
               <CardContent>{component}</CardContent>
            </Card>
         </Grid>
      </Grid>
   );
}
