import { Grid, TextField } from "@mui/material";
import React from "react";

interface EditPowerpointFormProps {
  description: string;
  setDescription: (value: string) => void;
  date: string;
  setDate: (value: string) => void;
  entity: string;
  setEntity: (value: string) => void;
}

const AddNewReportForm: React.FC<EditPowerpointFormProps> = ({
  description,
  setDescription,
  date,
  setDate,
  entity,
  setEntity,
}) => {
  return (
    <Grid item container xs={12} spacing={2}>
      <Grid item xs={12}>
        <TextField
          label="Tittel"
          fullWidth
          value={description}
          required
          onChange={(e) => setDescription(e.target.value)}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          label="Dato"
          type="date"
          fullWidth
          required
          value={date}
          onChange={(e) => setDate(e.target.value)}
          InputLabelProps={{
            shrink: true,
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          label="Selskap"
          fullWidth
          required
          disabled
          value={entity}
          onChange={(e) => setEntity(e.target.value)}
        />
      </Grid>
    </Grid>
  );
};

export default AddNewReportForm;
