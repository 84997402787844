export function formatNumberInThousands(number: number): string {
   // Divide the number by 1000 to get thousands and round to one decimal place
   const numberInThousands = (number / 1000).toFixed(1);

   // Convert the result to a string, format it with spaces between thousands
   return parseFloat(numberInThousands).toLocaleString("en-US", { minimumFractionDigits: 1 }).replace(/,/g, " ");
}

export function formatNumberInMillions(number: number): string {
   // Divide the number by 1,000,000 to get millions and round to one decimal place
   const numberInMillions = (number / 1000000).toFixed(1);

   // Convert the result to a string, format it with spaces between millions
   return parseFloat(numberInMillions).toLocaleString("en-US", { minimumFractionDigits: 1 }).replace(/,/g, " ");
}
