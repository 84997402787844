import React from "react";
import "../css/LineChartLoader.css";

const LineChartLoader = () => {
   return (
      <div className="chart-container">
         <div className="line-placeholder">
            <div className="line line1"></div>
            <div className="line line2"></div>
            <div className="line line3"></div>
            <div className="line line4"></div>
            <div className="line line5"></div>
         </div>
      </div>
   );
};

export default LineChartLoader;
