import {
  AccountBalance,
  Close,
  Dashboard,
  Foundation,
  ListRounded,
  QueryStats,
  SupervisorAccount,
} from "@mui/icons-material";
import {
  Button,
  Collapse,
  Grid,
  IconButton,
  MenuItem,
  MenuList,
  Menu as MuiMenu,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
  useMediaQuery,
} from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Toolbar from "@mui/material/Toolbar";
import { useTheme } from "@mui/material/styles";
import "chart.js/auto";
import React, { useContext, useState } from "react";
import { FaBook } from "react-icons/fa6";
import { NavLink, Outlet, useNavigate } from "react-router-dom";
import "./App.css";
import DialogWindow from "./components/DialogWindow";
import { GriegLogo } from "./components/Logo";
import { AuthContext } from "./context/AuthContext";
import "./index.css";

type SubRoute = {
  link: string;
  title: string;
};
type RouteProps = {
  link: string;
  icon: React.ReactNode;
  title: string;
  authorizationLevels: Array<
    | "admin"
    | "accounting"
    | "grieg-foundation"
    | "shareholder"
    | "reporting"
    | "grieg-connect"
  >;
  subRoutes?: SubRoute[];
};

export const routes: RouteProps[] = [
  {
    link: "/",
    icon: <Dashboard />,
    title: "Dashboard",
    authorizationLevels: [
      "admin",
      "accounting",
      "grieg-foundation",
      "shareholder",
      "reporting",
      "grieg-connect",
    ],
  },
  {
    link: "/financials",
    icon: <AccountBalance />,
    title: "Aksjonærer",
    authorizationLevels: ["shareholder", "accounting", "admin"],
  },
  {
    link: "/grieg-foundation",
    icon: <Foundation />,
    title: "Grieg Foundation",
    authorizationLevels: ["grieg-foundation", "admin"],
    subRoutes: [
      {
        link: "/grieg-foundation/midler",
        title: "Midler",
      },
      {
        link: "/grieg-foundation/utdelinger",
        title: "Utdelinger",
      },
      {
        link: "/grieg-foundation/kunst",
        title: "Kunst",
      },
    ],
  },
  {
    link: "/reporting",
    icon: <QueryStats />,
    title: "Rapportering",
    authorizationLevels: ["admin", "reporting"],
  },
  {
    link: "/accounting",
    icon: <FaBook />,
    title: "Regnskap",
    authorizationLevels: ["accounting", "admin"],
    subRoutes: [
      {
        link: "accounting/profit-loss",
        title: "Resultatregnskap",
      },
      {
        link: "/accounting/balance-sheet",
        title: "Balanse",
      },
    ],
  },
  {
    link: "/admin",
    authorizationLevels: ["admin", "accounting"],
    icon: <SupervisorAccount />,
    title: "Administrasjon",
    subRoutes: [
      {
        link: "/admin/user-access",
        title: "Brukertilganger",
      },
      {
        link: "/admin/event-editor",
        title: "Transaksjoner",
      },
      {
        link: "/admin/dividends",
        title: "Utbytte",
      },
      {
        link: "/admin/key-figures",
        title: "Nøkkeltall",
      },
      {
        link: "/admin/quarterly-reporting",
        title: "Kvartalsrapportering",
      },
      {
        link: "/admin/trialbalance",
        title: "Saldobalanse",
      },
    ],
  },
];

function App() {
  return <Menu />;
}

function Menu() {
  const theme = useTheme();
  const isMobilePhone = useMediaQuery(theme.breakpoints.down("sm"));

  const [selected, setSelected] = useState<string>("");
  const [expanded, setExpanded] = useState<string[]>([]);
  const [clients, setClients] = useState<string[] | null>(null);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const [detailsViewOpen, setDetailsViewOpen] = useState<boolean>(false);
  const drawerWidth = isMobilePhone ? 0 : 240;

  const { loggedInUser, logOut } = useContext(AuthContext);

  const navigate = useNavigate();

  function returnToHomePage() {
    navigate("/");
  }

  function handleDetailsOpen() {
    setDetailsViewOpen(true);
  }

  function closeDetailsView() {
    setDetailsViewOpen(false);
  }

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogOut = () => {
    returnToHomePage();
    logOut && logOut();
    handleClose();
  };

  type MobileMenuProps = {
    children: React.ReactNode;
  };

  function MobileMenu({ children }: MobileMenuProps) {
    return (
      <div
        style={{
          position: "fixed",
          width: "100%",
          height: "100%",
          left: 0,
          padding: 0,
          paddingTop: 10,

          top: 56,
          backgroundColor: "white",
          zIndex: 0,
        }}
      >
        {children}
      </div>
    );
  }

  function routeIsValid(route: RouteProps): boolean {
    if (
      route.authorizationLevels.includes("accounting") &&
      loggedInUser?.isAccountingMember
    )
      return true;
    if (route.authorizationLevels.includes("admin") && loggedInUser?.isAdmin)
      return true;
    if (
      route.authorizationLevels.includes("grieg-foundation") &&
      loggedInUser?.isGriegFoundationMember
    )
      return true;
    if (
      route.authorizationLevels.includes("shareholder") &&
      loggedInUser?.isShareholder
    )
      return true;
    if (
      route.authorizationLevels.includes("reporting") &&
      loggedInUser?.isReportingMember
    )
      return true;
    if (
      route.authorizationLevels.includes("grieg-connect") &&
      loggedInUser?.isGriegConnectMember
    )
      return true;
    return false;
  }

  function routeHasChildren(route: RouteProps): boolean {
    return route.subRoutes !== undefined && route.subRoutes.length > 0;
  }

  function toggleRouteAsExpanded(route: RouteProps) {
    if (routeHasChildren(route)) {
      setExpanded((state) => {
        if (state.includes(route.link)) {
          return state.filter((item) => item !== route.link);
        }
        return [...state, route.link];
      });
    }
  }

  function routeIsExpanded(RouteProps: RouteProps): boolean {
    return expanded.includes(RouteProps.link);
  }

  /* useEffect(() => {
    try {
      loadClients();
    } catch (e) {
      alert(e);
    }
    async function loadClients() {
      try {
        let clients = await GetClients();
        setClients(clients);
      } catch (e) {
        alert(e);
      }
    }
  }, []); */

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <DialogWindow
        title="Brukerdetaljer"
        open={detailsViewOpen}
        onClose={closeDetailsView}
      >
        <TableContainer sx={{ p: 6 }}>
          <Table size="small">
            <TableBody>
              <TableRow>
                <TableCell>Navn</TableCell>
                <TableCell>{loggedInUser?.firstName}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Etternavn</TableCell>
                <TableCell>{loggedInUser?.lastName}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>E-post</TableCell>
                <TableCell>{loggedInUser?.email}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Id</TableCell>
                <TableCell>{loggedInUser?.id}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </DialogWindow>
      <AppBar
        position="fixed"
        sx={{ width: `calc(100% - ${drawerWidth}px)`, ml: `${drawerWidth}px` }}
      >
        <Toolbar>
          <Grid item container xs={6}>
            {isMobilePhone && <GriegLogo />}
          </Grid>
          <Grid item container xs={6} justifyContent={"right"}>
            <Grid item>
              <IconButton
                onClick={
                  isMobilePhone && open ? () => handleClose() : handleClick
                }
              >
                {isMobilePhone && open ? (
                  <Close sx={{ color: "white" }} />
                ) : (
                  <ListRounded sx={{ color: "white" }} />
                )}
              </IconButton>
            </Grid>
            {isMobilePhone && open && (
              <MobileMenu>
                <>
                  {routes
                    .filter((route) => routeIsValid(route))
                    .map((item, index) => (
                      <>
                        <MenuItem>
                          <NavLink
                            onClick={(e) => {
                              if (item.subRoutes) {
                                e.preventDefault();
                                toggleRouteAsExpanded(item);
                                return;
                              }
                              setSelected(item.link);
                              handleClose();
                            }}
                            style={{ textDecoration: "none", width: "100%" }}
                            to={item.link}
                            className={({ isActive, isPending }) => {
                              /* if (routeIsExpanded(item)) return "active"; */
                              return selected === item.link
                                ? "active"
                                : isPending
                                ? "pending"
                                : "navlink";
                            }}
                          >
                            <ListItemButton>
                              <ListItemIcon
                                sx={{
                                  color:
                                    selected === item.link
                                      ? "white"
                                      : undefined,
                                }}
                              >
                                {item.icon}
                              </ListItemIcon>
                              <ListItemText
                                primaryTypographyProps={{ fontSize: 12 }}
                                primary={item.title}
                              />
                            </ListItemButton>
                          </NavLink>
                        </MenuItem>
                        <Collapse in={routeIsExpanded(item)}>
                          {item.subRoutes?.map((subRoute) => (
                            <ListItem
                              key={subRoute.title}
                              disablePadding
                              sx={{}}
                            >
                              <NavLink
                                onClick={(event) => {
                                  setSelected(subRoute.link);
                                  handleClose();
                                }}
                                style={{
                                  textDecoration: "none",
                                  width: "100%",
                                }}
                                to={subRoute.link}
                                className={({ isActive, isPending }) =>
                                  isActive
                                    ? "subRoute-active"
                                    : isPending
                                    ? "pending"
                                    : "navlink"
                                }
                              >
                                <ListItemButton>
                                  <ListItemText
                                    primaryTypographyProps={{
                                      fontSize: 10,
                                      color:
                                        selected === subRoute.link
                                          ? "white"
                                          : undefined,
                                    }}
                                    sx={{ pl: 7 }}
                                    primary={subRoute.title}
                                  />
                                </ListItemButton>
                              </NavLink>
                            </ListItem>
                          ))}
                        </Collapse>
                      </>
                    ))}
                  <Divider />
                  <Grid item xs={12} container>
                    <ListItem>
                      <ListItemButton onClick={() => handleLogOut()}>
                        <ListItemText
                          primaryTypographyProps={{
                            fontSize: 11,
                            color: "black",
                          }}
                          primary={"Logg ut"}
                        />
                      </ListItemButton>
                    </ListItem>
                  </Grid>
                  <Divider />
                  <ListItem>
                    <ListItemButton disabled={true}>
                      <ListItemText
                        primaryTypographyProps={{
                          fontSize: 11,
                          color: "black",
                        }}
                      >
                        {`Du er pålogget som `}
                        <b>{loggedInUser?.displayName}</b>
                      </ListItemText>
                    </ListItemButton>
                  </ListItem>
                  <Grid item xs={12} sx={{ pl: 3 }}>
                    <Button
                      sx={{ fontSize: 8, mt: 1 }}
                      onClick={() => handleDetailsOpen()}
                    >
                      Vis brukerdetaljer
                    </Button>
                  </Grid>
                </>
              </MobileMenu>
            )}
            {!isMobilePhone && (
              <MuiMenu anchorEl={anchorEl} open={open} onClose={handleClose}>
                <MenuList dense>
                  <Grid item xs={12} container>
                    <Grid item xs={12}>
                      <MenuItem
                        sx={{ fontSize: 11, pl: 2, pt: 1, pb: 1 }}
                        onClick={handleLogOut}
                      >
                        Logout
                      </MenuItem>
                    </Grid>
                  </Grid>
                  <Divider />

                  <MenuItem disabled>
                    <Typography sx={{ fontSize: 9 }} variant="body2">
                      {`You are logged in as `}
                      <b>{loggedInUser?.displayName}</b>
                    </Typography>
                  </MenuItem>
                  <Grid item xs={12}>
                    <Button
                      sx={{ fontSize: 8, mt: 1 }}
                      onClick={() => handleDetailsOpen()}
                    >
                      Vis brukerdetaljer
                    </Button>
                  </Grid>
                </MenuList>
              </MuiMenu>
            )}
          </Grid>
        </Toolbar>
      </AppBar>
      {!isMobilePhone && (
        <Drawer
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            "& .MuiDrawer-paper": {
              width: drawerWidth,
              boxSizing: "border-box",
              border: "none",
              /* backgroundColor: "hsl(170, 0%, 99%)", */
            },
          }}
          variant="permanent"
          anchor="left"
        >
          <Toolbar sx={{ backgroundColor: "primary.main" }}>
            <Button onClick={() => returnToHomePage()}>
              <GriegLogo />
            </Button>
          </Toolbar>
          <Divider />
          <List>
            {routes
              .filter((route) => routeIsValid(route))
              .map((item, index) => (
                <>
                  <ListItem key={item.title} disablePadding>
                    <NavLink
                      onClick={(e) => {
                        if (item.subRoutes) {
                          e.preventDefault();
                          toggleRouteAsExpanded(item);
                          return;
                        }
                        setSelected(item.link);
                      }}
                      style={{ textDecoration: "none", width: "100%" }}
                      to={item.link}
                      className={({ isActive, isPending }) => {
                        /* if (routeIsExpanded(item)) return "active"; */
                        return selected === item.link
                          ? "active"
                          : isPending
                          ? "pending"
                          : "navlink";
                      }}
                    >
                      <ListItemButton>
                        <ListItemIcon
                          sx={{
                            color: selected === item.link ? "white" : undefined,
                          }}
                        >
                          {item.icon}
                        </ListItemIcon>
                        <ListItemText
                          primaryTypographyProps={{ fontSize: 12 }}
                          primary={item.title}
                        />
                      </ListItemButton>
                    </NavLink>
                  </ListItem>
                  <Collapse in={routeIsExpanded(item)}>
                    {item.subRoutes?.map((subRoute) => (
                      <ListItem key={subRoute.title} disablePadding sx={{}}>
                        <NavLink
                          onClick={(event) => setSelected(subRoute.link)}
                          style={{ textDecoration: "none", width: "100%" }}
                          to={subRoute.link}
                          className={({ isActive, isPending }) =>
                            isActive
                              ? "subRoute-active"
                              : isPending
                              ? "pending"
                              : "navlink"
                          }
                        >
                          <ListItemButton>
                            <ListItemText
                              primaryTypographyProps={{
                                fontSize: 10,
                                color:
                                  selected === subRoute.link
                                    ? "white"
                                    : undefined,
                              }}
                              sx={{ pl: 7 }}
                              primary={subRoute.title}
                            />
                          </ListItemButton>
                        </NavLink>
                      </ListItem>
                    ))}
                  </Collapse>
                </>
              ))}
          </List>
        </Drawer>
      )}
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
        }}
      >
        <Toolbar />
        <Outlet />
      </Box>
    </Box>
  );
}

export default App;
