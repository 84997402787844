import { DefaultAxiosPostRequest } from "./ApiBase";
import { LiquidityFiguresDto } from "./GetLiquidityFigures";

export interface PostLiquidityFiguresDto {
    entityId: number;
    period: string;
    bankinnskudd: number;
    pengemarkedsfond: number;
    obligasjoner: number;
    aksjefond: number;
    trekkfasiliteter: number;
    comment: string;
}

export async function PostLiquidityFigures(values: PostLiquidityFiguresDto): Promise<LiquidityFiguresDto> {
    try {
       let result = await DefaultAxiosPostRequest<LiquidityFiguresDto, PostLiquidityFiguresDto>(
          `PostLiquidityFigures`, values
       );
       return result.data;
    } catch (e) {
       throw e;
    }
 }
