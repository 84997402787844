import {
  Box,
  Button,
  Grid,
  MenuItem,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { NumericFormat } from "react-number-format";
import { Bounce, Flip, Slide, toast, Zoom } from "react-toastify";
import { GetLiquidityFigures } from "../api/GetLiquidityFigures";
import { GetReportingEntities } from "../api/GetReportingEntities";
import {
  PostLiquidityFigures,
  PostLiquidityFiguresDto,
} from "../api/PostLiquidityFigures";
import { ReportingEntity } from "../types/KeyMetrics";
import { generateReportingPeriods } from "./KeyMetricForm";

interface FormData {
  entity: string;
  period: string;
  bankinnskudd: string;
  pengemarkedsfond: string;
  obligasjoner: string;
  aksjefond: string;
  trekkfasiliteter: string;
  comment: string;
}

export function transformQuarter(quarterString: string): string {
  // Extract the quarter and year from the input string
  const quarter = quarterString.charAt(1);
  const year = "20" + quarterString.slice(2);

  // Map the quarter number to its corresponding text representation
  const quarterMap: { [key: string]: string } = {
    "1": "1st Quarter",
    "2": "2nd Quarter",
    "3": "3rd Quarter",
    "4": "4th Quarter",
  };

  // Construct the final string
  const quarterText = quarterMap[quarter];
  return `${quarterText} ${year}`;
}

const LiquidityForm = () => {
  const [formData, setFormData] = useState<FormData>({
    entity: "",
    period: "",
    bankinnskudd: "",
    pengemarkedsfond: "",
    obligasjoner: "",
    aksjefond: "",
    trekkfasiliteter: "",
    comment: "",
  });

  const [reportingEntities, setReportingEntities] = useState<ReportingEntity[]>(
    []
  );

  const [selectedEntity, setSelectedEntity] = useState<string | null>(null);
  const [reportingPeriods, setReportingPeriods] = useState<string[]>(
    generateReportingPeriods()
  );
  const [selectedPeriod, setSelectedPeriod] = useState<string>("");

  const [isLoading, setIsLoading] = useState(false);

  // Fetch reporting entities on component mount
  useEffect(() => {
    const fetchReportingEntities = async () => {
      try {
        const response = await GetReportingEntities();
        setReportingEntities(response);
      } catch (error) {
        console.error("Error fetching reporting entities", error);
      }
    };

    fetchReportingEntities();
  }, []);

  useEffect(() => {
    const fetchFiguresForSelectedPeriod = async () => {
      if (selectedEntity && selectedPeriod) {
        try {
          const response = await GetLiquidityFigures({
            reportingEntityId: parseInt(selectedEntity),
            reportingPeriod: selectedPeriod,
          });

          const data: FormData = {
            entity: response.entityId.toString(),
            bankinnskudd: response.bankinnskudd.toString(),
            obligasjoner: response.obligasjoner.toString(),
            aksjefond: response.aksjefond.toString(),
            trekkfasiliteter: response.trekkfasiliteter.toString(),
            pengemarkedsfond: response.pengemarkedsfond.toString(),
            period: response.period,
            comment: response.comment ?? "",
          };

          setFormData(data);
        } catch (error) {
          console.error("Error fetching key metric values", error);
        }
      }
    };

    fetchFiguresForSelectedPeriod();
  }, [selectedEntity, selectedPeriod]);

  const handleEntityChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedEntity(e.target.value);
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handlePeriodChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedPeriod(e.target.value);
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleReset = () => {
    setFormData({
      entity: "",
      period: "",
      bankinnskudd: "",
      pengemarkedsfond: "",
      obligasjoner: "",
      aksjefond: "",
      trekkfasiliteter: "",
      comment: "",
    });
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    // Submit the formData to the server here
    console.log("Submitting data:", formData);
    setIsLoading(true);

    try {
      const data: PostLiquidityFiguresDto = {
        period: formData.period,
        entityId: parseInt(formData.entity),
        aksjefond: parseFloat(formData.aksjefond),
        obligasjoner: parseFloat(formData.obligasjoner),
        pengemarkedsfond: parseFloat(formData.pengemarkedsfond),
        bankinnskudd: parseFloat(formData.bankinnskudd),
        trekkfasiliteter: parseFloat(formData.trekkfasiliteter),
        comment: formData.comment,
      };

      await PostLiquidityFigures(data);
      toast.success("Successfully updated record!");
    } catch (error) {
      console.error("Error submitting data", error);
      toast.error("Unable to upload data. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Grid component={Paper} item container xs={12} sx={{ padding: 2 }}>
      <Box component="form" onSubmit={handleSubmit} sx={{ width: "100%" }}>
        <Typography variant="h6" gutterBottom>
          Likviditet
        </Typography>
        <Grid container spacing={2} sx={{ mt: 1 }}>
          {/* Entity Dropdown */}
          <Grid item xs={12} md={6}>
            <TextField
              size="small"
              select
              label="Entity Reporting"
              name="entity"
              value={formData.entity}
              onChange={handleEntityChange}
              fullWidth
            >
              {reportingEntities.map((option) => (
                <MenuItem key={option.entityId} value={option.entityId}>
                  {option.entityName}
                </MenuItem>
              ))}
            </TextField>
          </Grid>

          {/* Period Dropdown */}
          <Grid item xs={12} md={6}>
            <TextField
              size="small"
              select
              label="Period"
              name="period"
              value={formData.period}
              onChange={handlePeriodChange}
              fullWidth
              SelectProps={{
                MenuProps: {
                  PaperProps: {
                    style: {
                      maxHeight: 300, // Limit the height of the dropdown (in pixels)
                    },
                  },
                },
              }}
            >
              {reportingPeriods.map((option) => (
                <MenuItem key={option} value={option}>
                  {transformQuarter(option)}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={12} container spacing={2}>
            {/* Number Inputs */}
            {[
              { label: "Bankinnskudd", name: "bankinnskudd" },
              { label: "Pengemarkedsfond", name: "pengemarkedsfond" },
              { label: "Obligasjoner", name: "obligasjoner" },
              { label: "Aksjefond", name: "aksjefond" },
              { label: "Trekkfasiliteter", name: "trekkfasiliteter" },
            ].map((field) => (
              <Grid item xs={6} sm={4} key={field.name}>
                <NumericFormat
                  label={field.label}
                  value={formData[field.name as keyof FormData]}
                  name={field.name}
                  onValueChange={(values) => {
                    const { value } = values;
                    setFormData({
                      ...formData,
                      [field.name]: value,
                    });
                  }}
                  size="small"
                  thousandSeparator=" "
                  customInput={TextField} // Ensure customInput is recognized as a valid component
                  inputProps={{ sx: { textAlign: "right " } }}
                  fullWidth
                  required
                />
              </Grid>
            ))}
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Kommentar til perioden"
              multiline
              rows={8}
              variant="outlined"
              fullWidth
              value={formData["comment"]}
              onChange={(event) =>
                setFormData({
                  ...formData,
                  ["comment"]: event.target.value,
                })
              }
            />
          </Grid>
          {/* Buttons */}
          <Grid item xs={12}>
            <Button type="submit" variant="contained" color="primary">
              Lagre
            </Button>
            <Button
              onClick={handleReset}
              variant="outlined"
              color="secondary"
              sx={{ ml: 2 }}
            >
              Reset
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Grid>
  );
};

export default LiquidityForm;
