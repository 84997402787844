import { DefaultAxiosGetRequest } from "./ApiBase";
import { TransactionQuery } from "./GetTrialBalance";

export interface Product {
   id: number;
   name: string;
   productGroup: string;
   groupType: string;
   groupArea: string;
   amount: number;
   month: number;
   year: number;
   quarter: string;
   customer: string;
}

export async function GetRevenuePerProduct(parameters: TransactionQuery): Promise<Product[]> {
   try {
      let result = await DefaultAxiosGetRequest<Product[], TransactionQuery>(`GetRevenuePerProduct`, parameters);
      return result.data;
   } catch (e) {
      throw e;
   }
}
