import { DefaultAxiosGetRequest } from "./ApiBase";

export interface User {
   id: string | null;
   displayName: string | null;
   firstName: string | null;
   lastName: string | null;
   email: string | null;
   memberships: string[];
   clients: string[];
   isAdmin: boolean;
   isShareholder: boolean;
   isGriegFoundationMember: boolean;
   isAccountingMember: boolean;
   isReportingMember: boolean;
   isGriegConnectMember: boolean;
}

export async function GetUser(): Promise<User> {
   try {
      let result = await DefaultAxiosGetRequest<User, undefined>(`GetUser`, undefined);
      return result.data;
   } catch (e) {
      throw e;
   }
}
