import { Chip, Divider, Grid, Paper, useTheme } from "@mui/material";
import { useState } from "react";
import {
  AccountTypes,
  GetTrialBalance,
  TrialBalanceAccountDto,
} from "../../api/GetTrialBalance";
import FinancialTable from "../../components/FinancialTable";
import TimePeriodSelector, {
  SearchCriteriaParameters,
} from "../../components/SearchCriteria";
import SkeletonTable from "../../components/SkeletonTable";
import { Account, AccountingPeriod, AccountingSet } from "../../types/account";
import {
  getDistinctObjectsByProperty,
  getDistinctObjectsByPropertyAndTransform,
} from "../../utils/getDistinctObjects";

export function getLastFinishedQuarter(): string {
  const today = new Date();
  const currentMonth = today.getMonth() + 1; // getMonth() returns 0-11, so add 1 to get 1-12
  const currentYear = today.getFullYear();

  let lastQuarter: number;
  let lastQuarterYear: number;

  if (currentMonth <= 3) {
    // Q1: Jan-Mar
    lastQuarter = 4;
    lastQuarterYear = currentYear - 1;
  } else if (currentMonth <= 6) {
    // Q2: Apr-Jun
    lastQuarter = 1;
    lastQuarterYear = currentYear;
  } else if (currentMonth <= 9) {
    // Q3: Jul-Sep
    lastQuarter = 2;
    lastQuarterYear = currentYear;
  } else {
    // Q4: Oct-Dec
    lastQuarter = 3;
    lastQuarterYear = currentYear;
  }

  const lastQuarterString = `Q${lastQuarter}${lastQuarterYear
    .toString()
    .slice(-2)}`;
  return lastQuarterString;
}

export function convertToPreviousYear(period: string): string {
  const year = parseInt(period.slice(0, 4));
  const month = period.slice(4, 6);

  const previousYear = year - 1;
  const previousPeriod = `${previousYear}${month}`;

  return previousPeriod;
}

// Example usage
console.log(getLastFinishedQuarter()); // Output will depend on today's date

function getPreviousYearQuarter(period: string): string {
  const quarter = period.slice(0, 2); // Extract the quarter part (e.g., 'Q3')
  const year = parseInt(period.slice(2), 10); // Extract the year part (e.g., '23')

  const previousYear = year - 1; // Calculate the previous year

  return `${quarter} ${previousYear}`; // Return the new quarter string
}

function getPreviousYearPeriod(period: string): string {
  const type = period.startsWith("Q") ? "Q" : "YTD";
  const year = parseInt(period.slice(type === "Q" ? 2 : 3), 10); // Extract the year part

  const previousYear = year - 1; // Calculate the previous year

  return `${type}${previousYear}`; // Return the new period string
}

export function getLatestQuarter(periods: string[]): string {
  return periods.sort((a, b) => {
    const [quarterA, yearA] = parseQuarter(a);
    const [quarterB, yearB] = parseQuarter(b);

    if (yearA !== yearB) {
      return yearB - yearA; // Sort by year in descending order
    }

    return quarterB - quarterA; // Sort by quarter in descending order
  })[0];
}

function parseQuarter(period: string): [number, number] {
  const quarter = parseInt(period.slice(1, 2), 10);
  const year = parseInt(period.slice(2), 10);
  return [quarter, year];
}

type QuarterlyReportingProps = {
  client: string;
};
export default function QuarterlyReporting({
  client,
}: QuarterlyReportingProps) {
  const [data, setData] = useState<TrialBalanceAccountDto[]>([]);
  const [comparisonData, setComparisonData] = useState<
    TrialBalanceAccountDto[]
  >([]);
  const [accountSet, setAccountSet] = useState<AccountingSet>();
  const [loading, setLoading] = useState<boolean>(false);
  const [availablePeriods, setAvailablePeriods] = useState<string[]>([]);
  const [selectedPeriods, setSelectedPeriods] = useState<string[]>([]);
  const [currentYear, setCurrentYear] = useState<string>("YTD24");
  const theme = useTheme();
  const valuesToSwitch: AccountTypes[] = [3, 4, 5, 6, 8];

  async function loadData(periodFrom: string, periodTo: string) {
    try {
      setLoading(true);
      const [data, comparisonData] = await Promise.all([
        GetTrialBalance({
          periodFrom: periodFrom.toString(),
          periodTo: periodTo.toString(),
          clients: [client],
          aggregateTimePeriods: false,
          includeBalanceSheet: false,
        }),
        GetTrialBalance({
          periodFrom: convertToPreviousYear(periodFrom),
          periodTo: convertToPreviousYear(periodTo),
          clients: [client],
          aggregateTimePeriods: false,
          includeBalanceSheet: false,
        }),
      ]);
      setData(data);
      setComparisonData(comparisonData);
      let completeDataset = [...data, ...comparisonData];

      let accountingPeriods = getAccountingPeriods(data);

      const latestQuarter = getLatestQuarter(
        accountingPeriods.map((item) => item.name)
      );

      setCurrentYear(`YTD${latestQuarter.slice(3)}`);
      const comparisonQuarter = getPreviousYearQuarter(latestQuarter);
      const YTD = `YTD${latestQuarter.slice(3)}`;
      const comparisonYTD = getPreviousYearPeriod(
        `YTD${latestQuarter.slice(3)}`
      );

      accountingPeriods.push({
        name: comparisonQuarter,
        month: 0,
        year: 0,
        quarter: comparisonQuarter,
      });

      accountingPeriods.push({
        name: YTD,
        month: 0,
        year: 0,
        quarter: "",
      });

      accountingPeriods.push({
        name: comparisonYTD,
        month: 0,
        year: 0,
        quarter: "",
      });

      setAvailablePeriods(accountingPeriods.map((item) => item.name));
      setSelectedPeriods([latestQuarter, YTD]);
      let accounts = getDistinctAccounts(completeDataset);

      accounts.forEach((account) => {
        account.accountingPeriods = accountingPeriods.map((period) => ({
          name: period.name,
          value: calculateAmount(account.trialBalanceAccounts, period.name),
        }));
      });

      let EBIT: Account = {
        name: "Driftsresultat",
        highlight: true,
        accountingPeriods: accountingPeriods.map((item) => ({
          value: calculateEBIT(completeDataset, item.name),
          name: item.name,
        })),
        trialBalanceAccounts: [],
      };

      let pretaxIncome: Account = {
        name: "Resultat før skatt",
        highlight: true,
        accountingPeriods: accountingPeriods.map((item) => ({
          value: calculatePretaxIncome(completeDataset, item.name),
          name: item.name,
        })),
        trialBalanceAccounts: [],
      };

      //Insert EBIT before Netto finans
      let index = accounts.findIndex((item) => item.name === "Netto finans");
      if (index > -1) {
        accounts.splice(index, 0, EBIT);
      }

      accounts.push(pretaxIncome);

      let accountSet: AccountingSet = {
        name: "Resultatregnskap",
        entity: client,
        unit: "MNOK",
        accountingPeriods: accountingPeriods,
        accounts: accounts,
        comments: [],
      };

      setAccountSet(accountSet);
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  }

  const handleChipClick = (quarter: string) => {
    setSelectedPeriods((prevSelected) =>
      prevSelected.includes(quarter)
        ? prevSelected.filter((q) => q !== quarter)
        : [...prevSelected, quarter]
    );
  };

  function hightlightPeriod(period: string) {
    return selectedPeriods.includes(period);
  }

  function calculateAmount(
    accounts: TrialBalanceAccountDto[],
    period?: string
  ): number {
    if (period?.startsWith("YTD")) {
      let year = parseInt(period.slice(3, 5));
      accounts = accounts.filter((item) => item.year === 2000 + year);
    }

    let filteredAccounts = period
      ? period.startsWith("YTD")
        ? accounts
        : accounts.filter((acc) => acc.quarter === period)
      : accounts;

    return filteredAccounts.reduce(
      (value, item) =>
        valuesToSwitch.includes(item.type ?? 1)
          ? (value -= item.amount)
          : (value += item.amount),
      0
    );
  }

  function calculateEBIT(data: TrialBalanceAccountDto[], period?: string) {
    if (period?.startsWith("YTD")) {
      let year = parseInt(period.slice(3, 5)) + 2000;
      data = data.filter((item) => item.year === year);
    }

    return data
      .filter(
        (item) =>
          (period
            ? period.startsWith("YTD")
              ? true
              : item.quarter === period
            : true) && [6, 7].includes(item.accountTypeId)
      )
      .reduce((value, item) => (value -= item.amount), 0);
  }

  function calculatePretaxIncome(
    data: TrialBalanceAccountDto[],
    period?: string
  ) {
    if (period?.startsWith("YTD")) {
      let year = parseInt(period.slice(3, 5)) + 2000;
      data = data.filter((item) => item.year === year);
    }
    return data
      .filter(
        (item) =>
          (period
            ? period.startsWith("YTD")
              ? true
              : item.quarter === period
            : true) && [6, 7, 8, 9].includes(item.accountTypeId)
      )
      .reduce((value, item) => (value -= item.amount), 0);
  }

  function getAccountingPeriods(
    data: TrialBalanceAccountDto[]
  ): AccountingPeriod[] {
    let accountingPeriods: AccountingPeriod[] =
      getDistinctObjectsByPropertyAndTransform(data, "quarter", (c) => ({
        name: c.quarter,
        month: c.month,
        year: c.year,
        quarter: c.quarter,
      })).sort((a, b) => (a.name > b.name ? 1 : -1));
    return accountingPeriods;
  }

  function getDistinctAccounts(data: TrialBalanceAccountDto[]): Account[] {
    let captions = getDistinctObjectsByProperty(
      data,
      "financialStatementCaption"
    ).sort(
      (a, b) =>
        (a.financialStatementRank ?? -1) - (b.financialStatementRank ?? -1)
    );

    let accounts: Account[] = [];
    captions.forEach((item) => {
      let relevantAccounts = data.filter(
        (item2) =>
          item.financialStatementCaption === item2.financialStatementCaption
      );

      let acc: Account = {
        name: item.financialStatementCaption ?? "",
        highlight: false,
        accountingPeriods: [],
        trialBalanceAccounts: relevantAccounts,
      };
      accounts.push(acc);
    });
    return accounts;
  }

  const onSearch = (parameters: SearchCriteriaParameters) => {
    loadData(parameters.startPeriod, parameters.endPeriod);
  };

  return (
    <Grid item component={Paper} container xs={12} spacing={2} padding={2}>
      <Grid item xs={12}>
        <Grid item xs={12} padding={"12px"}>
          <TimePeriodSelector
            client={client}
            data={accountSet}
            onSearch={onSearch}
          />
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
      {availablePeriods.length > 0 && (
        <>
          <Grid item xs={12}>
            <Grid container spacing={1}>
              {availablePeriods.map((period) => (
                <Grid item>
                  <Chip
                    key={period}
                    label={period}
                    clickable
                    onClick={() => handleChipClick(period)}
                    style={{
                      backgroundColor: selectedPeriods.includes(period)
                        ? theme.palette.primary.main
                        : undefined,
                      color: selectedPeriods.includes(period)
                        ? theme.palette.primary.contrastText
                        : undefined,
                    }}
                  />
                </Grid>
              ))}
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
        </>
      )}
      <Grid item xs={12}>
        {loading ? (
          <SkeletonTable rows={6} cols={5} />
        ) : (
          <Grid item xs={12}>
            {accountSet && (
              <FinancialTable
                currentPeriod={currentYear}
                unit={accountSet?.unit}
                accountingPeriods={accountSet.accountingPeriods}
                accounts={accountSet?.accounts}
                highlightedPeriods={selectedPeriods}
                data={[...data, ...comparisonData]}
              />
            )}
          </Grid>
        )}
      </Grid>
    </Grid>
  );
}
