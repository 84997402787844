import { Add, Circle, Delete } from "@mui/icons-material";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Modal,
  Pagination,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Line } from "react-chartjs-2";
import { toast } from "react-toastify";
import {
  DeletePowerpointReport,
  DownloadGriegConnectPresentation,
  GetPowerpointReports,
  LineChartValuesDto,
  PowerpointReport,
  PowerpointReportDto,
  SavePowerpointReport,
  TableValuesDto,
} from "../api/DownloadKeyMetricsPresentation";
import AddNewReportForm from "./EditPowerpoint/AddNewReport";

interface EditPowerpointModalProps {
  open: boolean;
  onClose: () => void;
  report: PowerpointReportDto;
  onSave: (updatedReport: PowerpointReportDto) => void;
}

const EditPowerpointModal: React.FC<EditPowerpointModalProps> = ({
  open,
  onClose,
  report,
  onSave,
}) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [comments, setComments] = useState<string>("");
  const [selectedTab, setSelectedTab] = useState(0);
  const [currentItem, setCurrentItem] = useState<
    LineChartValuesDto | TableValuesDto | null
  >(null);

  const [entity, setEntity] = useState("Grieg Connect AS");
  const [date, setDate] = useState<string>(
    new Date().toISOString().split("T")[0]
  );
  const [description, setDescription] = useState<string>("");

  const [historicalReports, setHistoricalReports] = useState<
    PowerpointReport[]
  >([]);

  const [currentReport, setCurrentReport] = useState<PowerpointReport | null>(
    null
  );

  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [openConfirmationDialog, setOpenConfirmationDialog] =
    useState<boolean>(false);

  const [loading, setLoading] = useState(false);

  const toggleModal = () => {
    setModalOpen((state) => !state);
    setDescription("");
  };

  const handleSave = () => {
    const updatedReport = { ...report };
    if (selectedTab === 1) {
      updatedReport.lineCharts[currentIndex].comments.push(comments);
    } else {
      updatedReport.tables[currentIndex].comments.push(comments);
    }
    onSave(updatedReport);
    setComments("");
    //onClose();
  };

  const onSelectReport = (report: PowerpointReport) => {
    setCurrentReport(report);
    onSave(report.data);
  };

  useEffect(() => {
    async function fetchData() {
      const response = await GetPowerpointReports();
      setHistoricalReports(response);
    }
    fetchData();
  }, []);

  const handleReportSave = async () => {
    if (currentReport) {
      const response = await SavePowerpointReport(currentReport);
      toast.success("Successfully updated report!");
      return;
    }
  };

  const createNewReport = async () => {
    if (!description || description === "") {
      toast.error("Please enter a title");
      return;
    }

    if (!date || date === "") {
      toast.error("Please enter a date");
      return;
    }

    const reportToSave = { id: 0, data: { ...report } };
    const response = await SavePowerpointReport(reportToSave);
    toast.success("Successfully saved report!");
    toggleModal();
    GetPowerpointReports().then((response) => setHistoricalReports(response));
  };

  useEffect(() => {
    let updatedReport = { ...report };
    updatedReport.entity = entity;
    updatedReport.date = moment(date).format("DD/MM/YYYY");
    updatedReport.description = description;
    onSave(updatedReport);
  }, [entity, date, description]);

  const handleExport = async () => {
    try {
      setLoading(true);
      let result = await DownloadGriegConnectPresentation(report);
    } catch (error) {
      console.error("Export failed", error);
    } finally {
      setLoading(false);
    }
  };

  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setCurrentIndex(value - 1);
    setComments("");
  };

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue);
    setCurrentIndex(0);
    setComments("");
  };

  const onDelete = async (report: PowerpointReport) => {
    var result = await DeletePowerpointReport(report);
    if (result) {
      toast.success("Successfully deleted report!");
      if (currentReport?.id === report.id) setCurrentReport(null);
      GetPowerpointReports().then((response) => setHistoricalReports(response));
      return;
    }
    toast.error("Failed to delete report!");
  };

  const toggleConfirmationDialogModal = () => {
    setOpenConfirmationDialog(true);
  };

  const handleConfirmationDialogClose = () => {
    setOpenConfirmationDialog(false);
  };

  const onDeleteComment = (index: number) => {
    const updatedReport = { ...report };
    if (selectedTab === 1) {
      updatedReport.lineCharts[currentIndex].comments.splice(index, 1);
    } else {
      updatedReport.tables[currentIndex].comments.splice(index, 1);
    }
    onSave(updatedReport);
  };

  useEffect(() => {
    const item =
      selectedTab === 1
        ? report.lineCharts[currentIndex]
        : selectedTab === 2
        ? report.tables[currentIndex]
        : null;

    setCurrentItem(item);
  }, [currentIndex, selectedTab]);

  function isLineChartValuesDto(item: any): item is LineChartValuesDto {
    return item && item.periods !== undefined && item.values !== undefined;
  }

  function isTableValuesDto(item: any): item is TableValuesDto {
    return (
      item && item.accountingPeriods !== undefined && item.values !== undefined
    );
  }

  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          width: 1400,
          height: 950,
          bgcolor: "background.paper",
          p: 4,
          mx: "auto",
          mt: "10%",
        }}
      >
        <Box sx={{ width: "100%", height: 770 }}>
          <Tabs value={selectedTab} onChange={handleTabChange} sx={{ mb: 1 }}>
            <Tab label="Rapport" />
            <Tab disabled={!currentReport} label="Lisenser" />
            <Tab disabled={!currentReport} label="Resultat" />
          </Tabs>
          <Grid item container spacing={2}>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item container xs={12} justifyContent={"center"}>
              <Grid item>
                <Typography variant="button">
                  {currentReport && currentReport.data.description}
                </Typography>
              </Grid>
            </Grid>
            {/* <Grid item xs={12}>
              <Divider />
            </Grid> */}
          </Grid>
          {/* <ConfirmationDialog title="Bekreft valg" open={openConfirmationDialog} onClose={toggleConfirmationDialogModal} onConfirm={setCurrentReport(report)} message="Bekreft valg av rapport" /> */}
          <Grid item container xs={12} spacing={2} sx={{ mt: 2 }}>
            <Grid item xs={12} md={selectedTab === 0 ? 12 : 8}>
              {selectedTab === 0 && (
                <Box sx={{ height: 480, mt: 1 }}>
                  <Grid item container xs={12} spacing={2}>
                    <Dialog open={modalOpen} onClose={toggleModal}>
                      <DialogContent>
                        <Grid item container xs={12} spacing={3}>
                          <Grid item xs={12}>
                            <AddNewReportForm
                              date={date}
                              description={description}
                              entity={entity}
                              setDate={setDate}
                              setDescription={setDescription}
                              setEntity={setEntity}
                            />
                          </Grid>
                          <Grid
                            item
                            container
                            spacing={2}
                            xs={12}
                            justifyContent={"center"}
                          >
                            <Grid item>
                              <Button
                                variant="contained"
                                color="primary"
                                onClick={() => createNewReport()}
                              >
                                Legg til
                              </Button>
                            </Grid>
                            <Grid item>
                              <Button variant="outlined" onClick={toggleModal}>
                                Lukk
                              </Button>
                            </Grid>
                          </Grid>
                        </Grid>
                      </DialogContent>
                    </Dialog>
                    <Grid item container xs={12} sx={{ mt: 2 }}>
                      <Grid item xs={8}>
                        <Typography variant="h6">
                          Eksisterende rapporter
                        </Typography>
                      </Grid>
                      <Grid item container xs={4} justifyContent={"right"}>
                        <Tooltip title="Legg til ny rapport">
                          <IconButton onClick={toggleModal}>
                            <Add />
                          </IconButton>
                        </Tooltip>
                      </Grid>
                    </Grid>
                    <Grid item container xs={12}>
                      <TableContainer>
                        <Table
                          size="small"
                          sx={{
                            tr: {
                              "&:hover": {
                                cursor: "pointer",
                                backgroundColor: "lightgray",
                              },
                            },
                          }}
                        >
                          <TableHead>
                            <TableRow>
                              <TableCell>Navn</TableCell>
                              <TableCell>Dato</TableCell>
                              <TableCell>Selskap</TableCell>
                              <TableCell></TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {historicalReports.map((item) => (
                              <TableRow
                                sx={{
                                  backgroundColor:
                                    currentReport === item
                                      ? "primary.main"
                                      : undefined,
                                  td: {
                                    color:
                                      currentReport === item
                                        ? "primary.contrastText"
                                        : undefined,
                                  },
                                }}
                              >
                                <TableCell onClick={() => onSelectReport(item)}>
                                  {item.data.description}
                                </TableCell>
                                <TableCell onClick={() => onSelectReport(item)}>
                                  {item.data.date}
                                </TableCell>
                                <TableCell onClick={() => onSelectReport(item)}>
                                  {item.data.entity}
                                </TableCell>
                                <TableCell sx={{ width: "5%" }}>
                                  <IconButton onClick={() => onDelete(item)}>
                                    <Delete />
                                  </IconButton>
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Grid>
                  </Grid>
                </Box>
              )}
              {selectedTab === 1 && isLineChartValuesDto(currentItem) && (
                <Line
                  options={{
                    scales: {
                      y: {
                        beginAtZero: true,
                      },
                    },
                  }}
                  data={{
                    labels: (currentItem as LineChartValuesDto).periods,
                    datasets: [
                      {
                        label: currentItem.title,
                        data: currentItem.values,
                        borderColor: "gray", // "rgba(75, 192, 192, 1)",
                        backgroundColor: "gray", // "rgba(75, 192, 192, 0.2)",
                        tension: 0.4,
                        borderWidth: 2, // Set the line weight here
                      },
                    ],
                  }}
                />
              )}
              {selectedTab === 2 && isTableValuesDto(currentItem) && (
                <TableContainer
                  /* component={Paper} */
                  sx={{ height: 480, width: "100%" }}
                >
                  <Table
                    sx={{
                      td: {
                        fontFamily: "Poppins, sans-serif",
                        fontSize: "0.8rem",
                        padding: "10px",
                        borderBottom: "none",
                      },
                      th: {
                        fontFamily: "Poppins, sans-serif",
                        fontSize: "0.8rem",
                        padding: "10px",
                        borderBottom: "none",
                        fontWeight: "bold",
                      },
                    }}
                  >
                    <TableHead>
                      <TableRow>
                        <TableCell>
                          {(currentItem as TableValuesDto)?.title}
                        </TableCell>
                        {(
                          currentItem as TableValuesDto
                        )?.accountingPeriods?.map((period) => (
                          <TableCell
                            sx={{
                              backgroundColor: period.highlight
                                ? "lightgray"
                                : undefined,
                            }}
                            align="right"
                            key={period.name}
                          >
                            {period.name}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {(currentItem as TableValuesDto)?.values?.map((row) => (
                        <TableRow
                          key={row.name}
                          sx={{
                            td: {
                              fontWeight: row.highlight ? "bold" : undefined,
                            },
                          }}
                        >
                          <TableCell>{row.name}</TableCell>
                          {row.values.map((item, index) => (
                            <TableCell
                              sx={{
                                backgroundColor: currentItem.accountingPeriods[
                                  index
                                ]?.highlight
                                  ? "lightgray"
                                  : undefined,
                              }}
                              align="right"
                              key={index}
                            >
                              {item === 0
                                ? ""
                                : parseInt(
                                    (item / 1000).toFixed(0)
                                  ).toLocaleString("en-US")}
                            </TableCell>
                          ))}
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              )}
            </Grid>
            {selectedTab > 0 && (
              <Grid item xs={12} md={4}>
                <Grid container justifyContent={"center"}>
                  <Typography variant="button" gutterBottom>
                    <strong>Kommentarer</strong>
                  </Typography>
                  <Grid item xs={12}>
                    <Divider />
                  </Grid>
                </Grid>
                <Box
                  sx={{
                    height: 440,
                    overflowY: "auto",
                    p: 2,
                  }}
                >
                  <List>
                    {currentItem?.comments.map((comment, index) => (
                      <>
                        <ListItem key={index}>
                          {/* <ListItemAvatar>
                            <Avatar
                              sx={{ width: 16, height: 16, fontSize: 10 }}
                            ></Avatar>
                          </ListItemAvatar> */}
                          <ListItemIcon>
                            <Circle sx={{ fontSize: 6 }} />
                          </ListItemIcon>
                          <ListItemText
                            primary={
                              <Typography variant="caption">
                                {comment}
                              </Typography>
                            }
                          />
                          <ListItemSecondaryAction>
                            <IconButton
                              size="small"
                              onClick={() => onDeleteComment(index)}
                            >
                              <Delete fontSize="small" />
                            </IconButton>
                          </ListItemSecondaryAction>
                        </ListItem>
                        {/* <Divider light /> */}
                      </>
                    ))}
                  </List>
                </Box>
              </Grid>
            )}
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              {selectedTab > 0 && (
                <TextField
                  label="Legg til kommentar"
                  fullWidth
                  multiline
                  rows={4}
                  value={comments}
                  onChange={(e) => setComments(e.target.value)}
                  sx={{ mt: 1 }}
                />
              )}
            </Grid>
            {/* <Grid item container xs={2}>
              <IconButton onClick={handleSave}>
                <FaPlus />
              </IconButton>
            </Grid> */}
          </Grid>
        </Box>
        <Box sx={{ width: "100%", height: 40 }}>
          <Grid
            item
            container
            xs={12}
            height={"100%"}
            justifyContent={"center"}
            alignContent={"end"}
            spacing={1}
          >
            {selectedTab > 0 && (
              <Grid item>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleSave}
                  disabled={comments === ""}
                  /* sx={{ mt: 0 }} */
                >
                  LEGG TIL KOMMENTAR
                </Button>
              </Grid>
            )}
          </Grid>

          <Grid item container xs={12} justifyContent={"center"}>
            <Pagination
              count={
                selectedTab === 1
                  ? report.lineCharts.length
                  : selectedTab === 2
                  ? report.tables.length
                  : 1
              }
              page={currentIndex + 1}
              onChange={handlePageChange}
              sx={{ mt: 2 }}
            />
          </Grid>
          <Grid item container xs={12} justifyContent={"center"} spacing={1}>
            <Grid item>
              <Button
                variant="contained"
                disabled={!currentReport}
                onClick={handleReportSave}
                sx={{ mt: 2, minWidth: 140 }}
              >
                LAGRE
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="outlined"
                disabled={!currentReport || loading}
                onClick={handleExport}
                sx={{ mt: 2, minWidth: 140 }}
              >
                {loading ? <CircularProgress size={24} /> : "EKSPORTER"}
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Modal>
  );
};

export default EditPowerpointModal;

type ConfirmationDialogProps = {
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
  title: string;
  message: string;
};

export function ConfirmationDialog({
  open,
  onClose,
  onConfirm,
  title,
  message,
}: ConfirmationDialogProps) {
  <Dialog open={open} onClose={onClose}>
    <DialogTitle>{title}</DialogTitle>
    <DialogContent>
      <Typography variant="body1">{message}</Typography>
      <Grid item container xs={12} justifyContent={"center"} spacing={1}>
        <Grid item>
          <Button variant="contained" color="primary" onClick={onConfirm}>
            Bekreft
          </Button>
        </Grid>
        <Grid item>
          <Button variant="outlined" onClick={onClose}>
            Lukk
          </Button>
        </Grid>
      </Grid>
    </DialogContent>
  </Dialog>;
}
