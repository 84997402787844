import { DefaultAxiosGetRequest } from "./ApiBase";

export type Client = {
 name: string;
 group: string;
}

export async function GetClients(groups?: string[]) : Promise<Client[]> {
    try{
        let result = await DefaultAxiosGetRequest(`GetClients`, groups ? { groups: groups} : undefined);
        return result.data as Client[];
    }
    catch(e){
        throw e;
    }
}