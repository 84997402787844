import { KeyMetric } from "../types/KeyMetrics";
import { DefaultAxiosPostRequest } from "./ApiBase";

export interface PostKeyMetric {
    name: string;
    reportingEntityId: number;
    isActive: boolean;
    measurement: string;
}

export async function AddKeyMetric(data: KeyMetric): Promise<KeyMetric> {
    try {

       let newEntry : PostKeyMetric = {
        name: data.name,
        reportingEntityId: data.reportingEntityId,
        isActive: data.isActive, 
        measurement: data.measurement ?? ""
       };

       let result = await DefaultAxiosPostRequest<KeyMetric, PostKeyMetric>(
          `AddKeyMetric`, newEntry
       );
       return result.data;
    } catch (e) {
       throw e;
    }
 }
