import React from "react";
import { Skeleton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from "@mui/material";

type SkeletonTableProps = {
   rows?: number;
   cols?: number;
};

const SkeletonTable: React.FC<SkeletonTableProps> = ({ rows = 5, cols = 4 }) => {
   return (
      <TableContainer component={Paper}>
         <Table size="small">
            <TableHead>
               <TableRow>
                  {Array.from({ length: cols }).map((_, index) => (
                     <TableCell key={index}>
                        <Skeleton variant="text" sx={{ height: 25 }} />
                     </TableCell>
                  ))}
               </TableRow>
            </TableHead>
            <TableBody>
               {Array.from({ length: rows }).map((_, rowIndex) => (
                  <TableRow key={rowIndex}>
                     {Array.from({ length: cols }).map((_, colIndex) => (
                        <TableCell key={colIndex}>
                           <Skeleton variant="rectangular" height={25} />
                        </TableCell>
                     ))}
                  </TableRow>
               ))}
            </TableBody>
         </Table>
      </TableContainer>
   );
};

export default SkeletonTable;
