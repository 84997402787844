import React, { useRef } from "react";
import { Line } from "react-chartjs-2";
import { Paper, Box, Typography, Grid } from "@mui/material";
import {
  Chart,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { ChartEvent, ActiveElement } from "chart.js";
import { margin } from "@mui/system";

Chart.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

interface ChartProps {
  data: { name: string; values: number[] }[];
  labels: string[]; // Labels for the X-axis
  title: string;
  width?: number;
  height?: number;
  onPointClick?: (data: { label: string; value: number }) => void;
}

function roundToNearest(value: number): number {
  if (value < 1) {
    return Math.ceil(value); // Small numbers (< 1) should be rounded up to the nearest whole
  }
  const magnitude = Math.pow(10, Math.floor(Math.log10(value))); // Get magnitude (e.g. 10, 1000, etc.)
  return Math.ceil(value / magnitude) * magnitude; // Round to nearest magnitude
}

const DashboardChart: React.FC<ChartProps> = ({
  data,
  labels,
  title,
  width,
  height,
  onPointClick,
}) => {
  const chartRef = useRef<any>(null);

  const allValues = data.flatMap((item) => item.values);
  const maxValue = Math.max(...allValues);
  const maxChartValue = roundToNearest(maxValue * 1.1); // 110% of the largest value

  const chartData = {
    labels: labels,
    datasets: data.map((item, index) => ({
      label: item.name,
      data: item.values,
      borderColor: "white",
      /* backgroundColor: "rgba(255, 255, 255, 0.2)", */
      fill: false,
      tension: 0.4, // Add tension for smoothing the line
      pointBackgroundColor: "white", // Color of the data points
      pointHoverBackgroundColor: "white", // Color of the data points on hover
      borderWidth: 3, // Make the line thicker
    })),
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
        position: "bottom" as const,
        labels: {
          color: "white",
        },
      },
      title: {
        display: false,
        text: "Dashboard Chart",
        color: "white",
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
        ticks: {
          color: "white",
        },
      },
      y: {
        grid: {
          color: "rgba(173, 216, 230, 0.15)", // Light blue tone for gridlines
        },
        ticks: {
          color: "white",
        },
        beginAtZero: true, // Starts the y-axis at 0
        max: maxChartValue,
      },
    },
    onClick: (event: ChartEvent, elements: ActiveElement[]) => {
      if (elements.length > 0) {
        const element = elements[0]; // Get the clicked element
        const datasetIndex = element.datasetIndex; // Which dataset was clicked
        const dataIndex = element.index; // Index of the data point in the dataset

        const clickedLabel = labels[dataIndex]; // Get the X-axis label
        const clickedValue = data[datasetIndex].values[dataIndex]; // Get the Y-axis value

        // Trigger the custom onPointClick function with the clicked data point
        onPointClick &&
          onPointClick({
            label: clickedLabel,
            value: clickedValue,
          });
      }
    },
    onHover: (event: ChartEvent, elements: ActiveElement[]) => {
      const chart = chartRef.current;
      if (chart === null) return;

      if (elements.length) {
        chart.canvas.style.cursor = "pointer"; // Change cursor to pointer
      } else {
        chart.canvas.style.cursor = "default"; // Revert to default cursor
      }
    },
  };

  return (
    <Paper
      style={{
        background: "linear-gradient(to bottom, #05445e, #2e8bc0)", // Blue gradient background
        padding: "0px",
      }}
    >
      <Grid
        item
        container
        xs={12}
        sx={{
          fontFamily: "Roboto Light, sans-serif",
          color: "white",
          height: "50px",
        }}
        justifyContent={"center"}
        alignContent={"center"}
      >
        <Grid item>
          <Typography variant="h5">{title}</Typography>
        </Grid>
      </Grid>
      <Paper
        style={{
          background: "linear-gradient(to bottom, #05445e, #2e8bc0)", // Blue gradient background
          padding: "0px",
          display: "flex",
          flexDirection: "row",
          //height: "600px", // Increase the height of the Paper component
        }}
      >
        <Box style={{ flex: 2, padding: "20px", marginTop: 30 }}>
          <Line
            data={chartData}
            options={options}
            ref={chartRef}
            width={width}
            height={height}
          />
        </Box>
        {/* <Box
        style={{
          flex: 1,
          background: "rgba(46, 139, 192, 0.2)", // Slightly brighter blue tone
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          color: "white",
          padding: "20px", // Slight padding for the text
        }}
      >
        <Typography
          variant="h5"
          style={{ fontFamily: "Roboto, sans-serif", fontWeight: "bold" }}
        >
          {title}
        </Typography>
        <Typography
          variant="body1"
          style={{ fontFamily: "Roboto Light, sans-serif", marginTop: "10px" }}
          whiteSpace={"pre-line"}
        >
          {`Inntektene i 1. halvår 2024 har vært meget bra og er MNOK 4,0 høyere enn samme periode i fjor. Dette skyldes delvis finansmarkedene, økte midler fra eksisterende kunder og nye kunder i 2024.Som følge av nye lovkrav er det igangsatt flere store prosjekter som er kostnadskrevende.\n\n Dette gjelder bl.a. nytt IKT-regelverk (DORA) og nye krav til eierskapsstruktur. Det er fortsatt flere trusler og
            utfordringer som kan ha innvirkning på årets resultat. Det er store kunder som skal ut på anbud i høst, svake relative resultater og trusselen om indeksforvaltning.`}
        </Typography>
      </Box> */}
      </Paper>
    </Paper>
  );
};

export default DashboardChart;
