import { Button, Collapse, Grid } from "@mui/material";
import { useEffect, useState } from "react";
import {
  GetTrialBalance,
  TrialBalanceAccountDto,
} from "../../api/GetTrialBalance";
import { ReportingTable } from "../../components/Reporting/ReportingTable";
import SkeletonTable from "../../components/SkeletonTable";
import {
  getCurrentAndFirstPeriodOfYear,
  getPreviousYearPeriodFromString,
  getPreviousYearPeriods,
} from "../../utils/timePeriods";
import { generatePeriodsForYear } from "../../utils/trialBalanceCalculations";
import { PeriodSelection, PeriodSelector } from "./ProfitLoss";

type BalanceSheetProps = {
  showColumnsAs: "clients" | "periods";
  showFamilyOfficeClients: boolean;
  clients?: string[];
  language?: "no" | "en";
};

export default function BalanceSheet({
  showColumnsAs,
  showFamilyOfficeClients,
  clients,
  language = "no",
}: BalanceSheetProps) {
  const [loading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<TrialBalanceAccountDto[]>([]);
  const [comparisonData, setComparisonData] = useState<
    TrialBalanceAccountDto[]
  >([]);
  const [profitLoss, setProfitLoss] = useState<TrialBalanceAccountDto[]>([]);
  const [selectedPeriods, setSelectedPeriods] = useState<PeriodSelection[]>([]);
  const [selectedClients, setSelectedClients] = useState<string[]>(
    clients ?? []
  );
  const [showFilters, setShowFilters] = useState<boolean>(true);
  const [periods, setPeriods] = useState<string[]>([]);

  const loadData = async () => {
    try {
      if (selectedClients.length === 0) return;

      const [current, first] = getCurrentAndFirstPeriodOfYear();
      const [previousCurrent, previousFirst] = getPreviousYearPeriods();
      setLoading(true);

      const [currentSet, previousSet] = await Promise.all([
        GetTrialBalance({
          periodFrom: first,
          periodTo: current,
          clients: selectedClients,
          mappingCategory: 1,
          includeBalanceSheet: true,
          includeProfitLoss: true,
        }),
        GetTrialBalance({
          periodFrom: previousFirst,
          periodTo: previousCurrent,
          clients: selectedClients,
          mappingCategory: 1,
          includeBalanceSheet: true,
          includeProfitLoss: false,
        }),
      ]);

      //Set all profit/loss accounts to equity
      currentSet.forEach((item) => {
        if ([6, 7, 8, 9, 11].includes(item.accountTypeId)) {
          item.accountTypeId = 3;
          item.financialStatementCaption = "Resultat for året";
          item.financialStatementCaptionEnglish = "Net income for the year";
          item.accountType = "Egenkapital";
        }
      });

      setLoading(true);
      setData(currentSet);
      setComparisonData(previousSet);
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const periods: string[] = [];
    selectedPeriods.forEach((period) => {
      switch (period.state) {
        case "selected":
          periods.push(period.name);
          break;
        case "selected-with-comparison-figures":
          periods.push(period.name);
          periods.push(getPreviousYearPeriodFromString(period.name));
          break;
        default:
          break;
      }
      setPeriods(periods);
    });
  }, [selectedPeriods]);

  const availablePeriods = generatePeriodsForYear();

  const onPeriodSelected = (selectedPeriods: PeriodSelection[]) => {
    setSelectedPeriods(selectedPeriods);
  };

  const onClientSelected = (selectedClients: string[]) => {
    if (showFamilyOfficeClients) setSelectedClients(selectedClients);
  };

  const handleToggleFilters = () => {
    setShowFilters((state) => !state);
  };

  return (
    <Grid item container xs={12} spacing={2}>
      <Grid item container xs={12} spacing={2}>
        <Grid item xs={12}>
          <Collapse in={showFilters}>
            <PeriodSelector
              showFamilyOfficeClients={showFamilyOfficeClients}
              periods={availablePeriods}
              onClientSelected={onClientSelected}
              onPeriodSelected={onPeriodSelected}
              selection="client"
            />
          </Collapse>
        </Grid>
        <Grid item container xs={12} spacing={2} justifyContent={"center"}>
          <Grid item>
            <Button
              disabled={selectedClients.length === 0 || !showFilters}
              variant="contained"
              color="primary"
              onClick={loadData}
            >
              Hent data
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              onClick={handleToggleFilters}
            >
              {`${showFilters ? "Skjul" : "Vis"} filtre`}
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        {loading ? (
          <SkeletonTable rows={6} cols={5} />
        ) : (
          <ReportingTable
            type="balance"
            language={language}
            periods={periods}
            data={data}
            comparisonData={comparisonData}
            showColumnsAs="periods"
            clients={selectedClients}
          />
        )}
      </Grid>
    </Grid>
  );
}
