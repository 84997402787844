import { Box, Paper, Tab, Tabs, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import React, { useState } from "react";
import KeyMetricForm from "../../components/KeyMetricForm";
import LiquidityForm from "../../components/LiquidityForm";
import DashboardChart from "../../components/DashboardChart";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const Reporting: React.FC = () => {
  const [value, setValue] = useState(0);
  const theme = useTheme(); // Get the theme to access colors

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Paper sx={{ width: "100%", p: 2, boxShadow: 3 }}>
      <Box sx={{ bgcolor: theme.palette.primary.main, borderRadius: 1 }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
          TabIndicatorProps={{
            style: {
              backgroundColor: theme.palette.primary.light, // Color of the indicator (underline)
            },
          }}
          textColor="inherit"
        >
          <Tab
            label="Nøkkeltall"
            {...a11yProps(0)}
            sx={{
              color: theme.palette.primary.contrastText,
              bgcolor:
                value === 0
                  ? theme.palette.primary.light
                  : theme.palette.primary.main,
            }}
          />
          <Tab
            label="Likviditet"
            {...a11yProps(1)}
            sx={{
              color: theme.palette.primary.contrastText,
              bgcolor:
                value === 1
                  ? theme.palette.primary.light
                  : theme.palette.primary.main,
            }}
          />
          <Tab
            label="Annet"
            {...a11yProps(2)}
            sx={{
              color: theme.palette.primary.contrastText,
              bgcolor:
                value === 2
                  ? theme.palette.primary.light
                  : theme.palette.primary.main,
            }}
          />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <KeyMetricForm mode="regular" />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <LiquidityForm />
      </TabPanel>
      <TabPanel value={value} index={2}></TabPanel>
    </Paper>
  );
};

export default Reporting;
