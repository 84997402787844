import { Close, Filter1, Filter9Plus, Search } from "@mui/icons-material";
import {
  Box,
  Button,
  Checkbox,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  Paper,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { AddKeyMetric } from "../api/AddKeyMetric";
import DownloadKeyMetrics, {
  ReportedValuesDto,
} from "../api/DownloadKeyMetricsPresentation";
import { GetKeyMetrics } from "../api/GetKeyMetrics";
import { GetKeyMetricValues } from "../api/GetKeyMetricValues";
import { GetReportingEntities } from "../api/GetReportingEntities";
import { UpdateKeyMetric } from "../api/UpdateKeyMetric";
import {
  KeyMetric,
  KeyMetricValue,
  ReportingEntity,
} from "../types/KeyMetrics";
import { getDistinctObjectsByPropertyAndTransform } from "../utils/getDistinctObjects";
import Header from "./Header";
import KeyMetricForm from "./KeyMetricForm";
import LineChart from "./LineChart";
import DashboardChart from "./DashboardChart";

export function sortKeyMetricValuesByPeriod(
  keyMetricValues: KeyMetricValue[],
  reverse: boolean
): KeyMetricValue[] {
  return keyMetricValues.sort((a, b) => {
    const periodA = a.reportingPeriod;
    const periodB = b.reportingPeriod;

    // Extract the quarter and year from periodA and periodB
    const quarterA = parseInt(periodA[1]); // Get the quarter part (Q1 -> 1)
    const yearA = parseInt(periodA.slice(2)); // Get the year part (23 -> 2023)

    const quarterB = parseInt(periodB[1]); // Get the quarter part (Q1 -> 1)
    const yearB = parseInt(periodB.slice(2)); // Get the year part (23 -> 2023)

    // First, compare by year
    if (yearA !== yearB) {
      if (reverse) return yearA - yearB;
      return yearB - yearA;
    }

    // If the year is the same, compare by quarter
    if (reverse) return quarterA - quarterB;
    return quarterB - quarterA;
  });
}

const UpdateKeyMetrics = () => {
  const [keyMetrics, setKeyMetrics] = useState<KeyMetric[]>([]);
  const [reportingEntities, setReportingEntities] = useState<ReportingEntity[]>(
    []
  );
  const [selectedKeyMetric, setSelectedKeyMetric] = useState<
    KeyMetric | undefined
  >(undefined);

  const [reportedValues, setReportedValues] = useState<KeyMetricValue[] | null>(
    null
  );

  const [formData, setFormData] = useState<KeyMetric>({
    id: 0,
    name: "",
    reportingEntityId: 0,
    isActive: true,
    measurement: "",
    reportingEntity: null,
  });

  const [updateDialogIsOpen, setUpdateDialogIsOpen] = useState<boolean>(false);

  // Fetch existing KeyMetrics and ReportingEntities on component mount
  useEffect(() => {
    const fetchKeyMetrics = async () => {
      try {
        const response = await GetKeyMetrics();
        setKeyMetrics(response);
      } catch (error) {
        console.error("Error fetching key metrics", error);
      }
    };

    const fetchReportingEntities = async () => {
      try {
        const response = await GetReportingEntities();
        setReportingEntities(response);
      } catch (error) {
        console.error("Error fetching reporting entities", error);
      }
    };

    fetchKeyMetrics();
    fetchReportingEntities();
  }, []);

  // Handle form field changes
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, type, checked } = event.target;
    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  const handleChecked = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFormData((state) => ({ ...state, isActive: event.target.checked }));
  };

  // Populate the form when a KeyMetric is selected from the table
  const handleSelectKeyMetric = (keyMetric: KeyMetric) => {
    setSelectedKeyMetric((state) => {
      if (state?.id === keyMetric.id) return undefined;
      return keyMetric;
    });

    setFormData({
      id: keyMetric.id,
      name: keyMetric.name,
      reportingEntityId: keyMetric.reportingEntityId,
      isActive: keyMetric.isActive,
      measurement: keyMetric.measurement || "",
      reportingEntity: null,
    });
  };

  // Handle form submission
  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    try {
      if (selectedKeyMetric) {
        // Update existing KeyMetric
        await UpdateKeyMetric(formData);
      } else {
        // Create a new KeyMetric
        await AddKeyMetric(formData);
      }
      // Refresh the list after submission
      const updatedMetrics = await GetKeyMetrics();
      toast.success("Successfully updated record!");
      setKeyMetrics(updatedMetrics);
      setUpdateDialogIsOpen(false);
      handleReset();
    } catch (error) {
      console.error("Error submitting form", error);
    }
  };

  // Reset form
  const handleReset = () => {
    setSelectedKeyMetric(undefined);
    setFormData({
      id: 0,
      name: "",
      reportingEntityId: 0,
      isActive: true,
      measurement: "",
      reportingEntity: null,
    });
  };

  const addKeyFigure = () => {
    handleReset();
    setUpdateDialogIsOpen(true);
  };

  const updateKeyFigure = () => {
    setUpdateDialogIsOpen(true);
  };

  //Fetch key metric values based on selection
  const fetchValues = async () => {
    if (selectedKeyMetric && selectedKeyMetric.reportingEntityId) {
      let data = await GetKeyMetricValues({
        reportingEntityId: selectedKeyMetric.reportingEntityId,
      });

      let sorted = sortKeyMetricValuesByPeriod(
        data.filter((item) => item.keyMetricId === selectedKeyMetric.id),
        false
      );

      setReportedValues(sorted);
    }
  };

  //If values are updated, refresh table
  const onUpdateValues = () => {
    fetchValues();
  };
  useEffect(() => {
    if (selectedKeyMetric) {
      fetchValues();
    }
    setReportedValues(null);
  }, [selectedKeyMetric]);

  useEffect(() => {}, [reportedValues]);

  return (
    <Box component={Paper} sx={{ mt: 4, padding: 2 }}>
      {/* Table of Existing Key Metrics */}
      <Grid item container xs={12} spacing={2}>
        <Grid item xs={12} sx={{ mt: 4 }}>
          <Header title="Nøkkeltall" />
          <TableContainer component={Paper} sx={{ mt: 3 }}>
            <Table size="small">
              <TableHead>
                <TableRow
                  sx={{
                    th: {
                      fontSize: "0.7rem",
                      fontWeight: "bold",
                      padding: "8px",
                    },
                  }}
                >
                  <TableCell>Selskap</TableCell>
                  <TableCell>Navn</TableCell>
                  <TableCell>Måltall</TableCell>
                  <TableCell>Aktiv</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {keyMetrics.map((metric) => (
                  <TableRow
                    key={metric.id}
                    onClick={() => handleSelectKeyMetric(metric)}
                    style={{ cursor: "pointer" }}
                    sx={{
                      td: {
                        fontSize: "0.7rem",
                        padding: "8px",
                        color:
                          selectedKeyMetric === metric
                            ? "primary.contrastText"
                            : undefined,
                      },
                      backgroundColor:
                        selectedKeyMetric === metric
                          ? "primary.light"
                          : undefined,
                    }}
                  >
                    <TableCell>
                      {metric.reportingEntity?.entityName ?? ""}
                    </TableCell>
                    <TableCell>{metric.name}</TableCell>
                    <TableCell>{metric.measurement}</TableCell>
                    <TableCell>
                      <Checkbox checked={metric.isActive} disabled />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
        <Grid item container xs={12} justifyContent={"center"} spacing={1}>
          <Grid item>
            <Button onClick={() => addKeyFigure()} variant="contained">
              Legg til
            </Button>
          </Grid>
          <Grid item>
            <Button
              disabled={!selectedKeyMetric}
              variant="contained"
              color="secondary"
              onClick={() => updateKeyFigure()}
            >
              Oppdater
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <Dialog
        open={updateDialogIsOpen}
        onClose={() => setUpdateDialogIsOpen(false)}
      >
        <DialogTitle>
          {selectedKeyMetric ? "Oppdater verdier" : "Legg til ny verdi"}
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2} sx={{ mt: 3 }}>
            <Grid item xs={12}>
              <form onSubmit={handleSubmit}>
                {/* Name Field */}
                <Grid item container xs={12} spacing={2}>
                  <Grid item xs={12}>
                    <TextField
                      label="Name"
                      name="name"
                      value={formData.name}
                      onChange={handleChange}
                      fullWidth
                      required
                      margin="normal"
                    />
                  </Grid>
                  {/* Reporting Entity Dropdown */}
                  <Grid item xs={12}>
                    <TextField
                      select
                      label="Reporting Entity"
                      name="reportingEntityId"
                      value={formData.reportingEntityId}
                      onChange={handleChange}
                      fullWidth
                      required
                      margin="normal"
                      SelectProps={{
                        native: true,
                      }}
                    >
                      <option value="">Select Reporting Entity</option>
                      {reportingEntities.map((entity) => (
                        <option key={entity.entityId} value={entity.entityId}>
                          {entity.entityName}
                        </option>
                      ))}
                    </TextField>
                  </Grid>

                  <Grid item xs={12}>
                    {/* Measurement Field */}
                    <TextField
                      label="Measurement"
                      name="measurement"
                      value={formData.measurement}
                      onChange={handleChange}
                      fullWidth
                      margin="normal"
                    />
                  </Grid>
                  <Grid
                    item
                    container
                    xs={12}
                    justifyContent={"left"}
                    alignContent={"center"}
                  >
                    <FormControlLabel
                      control={
                        <Switch
                          checked={formData.isActive}
                          onChange={handleChecked}
                        />
                      }
                      label="Aktiv"
                    />
                  </Grid>
                  {/* IsActive Checkbox */}
                </Grid>

                <Grid
                  item
                  container
                  xs={12}
                  spacing={2}
                  justifyContent={"center"}
                >
                  {/* Submit and Reset Buttons */}
                  <Grid item>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      sx={{ mt: 2 }}
                    >
                      {selectedKeyMetric ? "Oppdater" : "Lagre"}
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      onClick={handleReset}
                      variant="outlined"
                      color="secondary"
                      sx={{ mt: 2 }}
                    >
                      Reset
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>

      <Collapse
        sx={{ mt: 4 }}
        in={reportedValues !== null && reportedValues.length > 0}
      >
        {reportedValues && reportedValues.length > 0 && (
          <Grid item xs={12}>
            {/* <Header title="Rapporterte tall" /> */}
            <KeyMetricValuesTable
              values={reportedValues}
              selectedItem={reportedValues[0].keyMetric.name}
              selectedEntity={
                reportedValues[0].keyMetric.reportingEntity?.entityName ?? ""
              }
              onUpdateValues={onUpdateValues}
            />
          </Grid>
        )}
      </Collapse>
    </Box>
  );
};

export default UpdateKeyMetrics;

// Utility function to format numbers with spaces between thousands
const formatNumberWithSpaces = (amount: number) => {
  return new Intl.NumberFormat("en-US", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(amount);
};

type KeyMetricValuesTableProps = {
  values: KeyMetricValue[];
  selectedEntity: string;
  selectedItem: string;
  onUpdateValues: () => void;
};

const KeyMetricValuesTable = ({
  values,
  selectedItem,
  selectedEntity,
  onUpdateValues,
}: KeyMetricValuesTableProps) => {
  const [selectedKeyMetricValue, setSelectedKeyMetricValue] = useState<
    KeyMetricValue | undefined
  >(undefined);

  const [allReportedValues, setAllReportedValues] = useState<
    ReportedValuesDto[] | null
  >(null);
  const [openModal, setOpenModal] = useState(false);
  const [openPreviewModal, setOpenPreviewModal] = useState(false);

  // Open the modal and set the selected key metric val|ue
  const handleRowClick = (keyMetricValue: KeyMetricValue) => {
    setSelectedKeyMetricValue(keyMetricValue);
    setOpenModal(true);
  };

  const onPointClick = (data: { label: string; value: number }) => {
    let selectedValue = values.find(
      (item) => item.reportingPeriod === data.label
    );
    if (selectedValue) {
      setSelectedKeyMetricValue(selectedValue);
      setOpenModal(true);
    }
  };

  // Handle form submission
  const handleSubmit = async (updatedValues: KeyMetricValue[]) => {
    try {
      setOpenModal(false);
      onUpdateValues();
    } catch (error) {
      console.error("Error updating key metric value", error);
    }
  };

  useEffect(() => {
    const getAllValues = async () => {
      let data = await GetKeyMetricValues({
        reportingEntityId: 0,
      });

      let allMetrics = getDistinctObjectsByPropertyAndTransform(
        data,
        "keyMetricId",
        (v) => v.keyMetric
      );

      const newValues: ReportedValuesDto[] = allMetrics.map((v) => {
        const sortedData = sortKeyMetricValuesByPeriod(
          data.filter((item) => item.keyMetricId === v.id),
          true
        );
        const labels = sortedData.map((item) => item.reportingPeriod);
        const values = sortedData.map((item) => item.amount);

        return {
          periods: labels,
          values: values,
          entity: v.reportingEntity?.entityName ?? "",
          title: v.name,
          axisTitle: v.measurement ?? "",
        };
      });

      setAllReportedValues(newValues);
    };
    getAllValues();
  }, [values]);

  const handlePreviewClick = () => {
    setOpenPreviewModal(true);
  };
  // Close the modal
  const handleCloseModal = () => {
    setOpenModal(false);
    /* setTimeout(() => {
      setSelectedKeyMetricValue(null);
    }, 1000); */
  };

  const sortedData = sortKeyMetricValuesByPeriod(values, true);
  const labels = sortedData.map((item) => item.reportingPeriod);
  const data = [
    {
      name: sortedData[0]?.keyMetric.name ?? "",
      values: sortedData.map((item) => item.amount),
    },
  ];

  const reportedValues: ReportedValuesDto[] = [
    {
      periods: labels,
      values: data[0].values,
      entity: sortedData[0]?.keyMetric.reportingEntity?.entityName ?? "",
      title: data[0].name,
      axisTitle: sortedData[0]?.keyMetric.measurement ?? "",
    },
  ];

  return (
    <Grid item container xs={12} spacing={2}>
      <Grid item container xs={12} spacing={2} justifyContent={"center"}>
        <Grid item>
          <Tooltip title="Last ned valgte nøkkeltall">
            <IconButton
              size="large"
              onClick={() => DownloadKeyMetrics(reportedValues)}
            >
              <Filter1 />
            </IconButton>
          </Tooltip>
        </Grid>
        <Grid item>
          <Tooltip title="Last ned alle nøkkeltall">
            <IconButton
              size="large"
              onClick={() => DownloadKeyMetrics(allReportedValues ?? [])}
            >
              <Filter9Plus />
            </IconButton>
          </Tooltip>
        </Grid>
        <Grid item>
          <Tooltip title="Preview">
            <IconButton size="large" onClick={handlePreviewClick}>
              <Search />
            </IconButton>
          </Tooltip>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <Grid item container xs={12} spacing={3}>
        <Grid item container xs={12}>
          <LineChart
            data={data}
            labels={labels}
            title={selectedKeyMetricValue?.keyMetric.name ?? ""}
            onPointClick={onPointClick}
            width={1000}
            height={250}
          />
        </Grid>
      </Grid>

      {/* Modal to display KeyMetricForm */}
      <Dialog open={openModal} onClose={handleCloseModal} fullWidth>
        <DialogTitle
          sx={{ color: "main.contrastText", backgroundColor: "main.primary" }}
        >
          Oppdater verdi
        </DialogTitle>
        <DialogContent>
          <KeyMetricForm
            mode="modal"
            keyMetricValue={selectedKeyMetricValue}
            onSubmit={handleSubmit}
            onClose={handleCloseModal}
          />
        </DialogContent>
      </Dialog>
      <Dialog
        open={openPreviewModal}
        onClose={() => setOpenPreviewModal(false)}
        maxWidth="lg"
      >
        <DialogContent sx={{ padding: 0 }}>
          <IconButton
            size="large"
            onClick={() => setOpenPreviewModal(false)}
            sx={{
              position: "absolute",
              top: "2px",
              right: "2px",
              color: "white",
            }}
          >
            <Close />
          </IconButton>
          <DashboardChart
            data={data}
            labels={labels}
            title={`${selectedEntity} - ${selectedItem}`}
            width={800}
            height={500}
          />
        </DialogContent>
      </Dialog>
    </Grid>
  );
};

function Test({ values }: any) {
  return (
    <Grid item xs={3}>
      <TableContainer component={Paper} sx={{ mt: 4, maxHeight: 400 }}>
        <Table size="small">
          <TableHead>
            <TableRow
              sx={{
                th: {
                  fontSize: "0.7rem",
                  fontWeight: "bold",
                  padding: "8px",
                },
              }}
            >
              {/* <TableCell>Tall</TableCell> */}
              <TableCell>Periode</TableCell>
              <TableCell align="right">Beløp</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {values.map((value: any, index: number) => (
              <TableRow
                key={index}
                hover
                style={{ cursor: "pointer" }}
                /* onClick={() => handleRowClick(value)} */
                sx={{ td: { fontSize: "0.9rem", padding: "8px" } }}
              >
                {/* <TableCell>{value.keyMetric.name}</TableCell> */}
                <TableCell>{value.reportingPeriod}</TableCell>
                <TableCell sx={{ textAlign: "right" }}>
                  {formatNumberWithSpaces(value.amount)}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Grid>
  );
}
