import { KeyMetric } from "../types/KeyMetrics";
import { DefaultAxiosPostRequest } from "./ApiBase";

export async function UpdateKeyMetric(values: KeyMetric): Promise<KeyMetric> {
    try {
       let result = await DefaultAxiosPostRequest<KeyMetric, KeyMetric>(
          `UpdateKeyMetric`, values
       );
       return result.data;
    } catch (e) {
       throw e;
    }
 }
