import { KeyMetricValue } from "../types/KeyMetrics";
import { DefaultAxiosGetRequest } from "./ApiBase";

type LiquidityFiguresQuery = {
    reportingEntityId: number;
    reportingPeriod: string;
}

export interface LiquidityFiguresDto {
    id: number;
    entityId: number;
    entityName: string;
    period: string;
    bankinnskudd: number;
    pengemarkedsfond: number;
    obligasjoner: number;
    aksjefond: number;
    trekkfasiliteter: number;
    comment?: string;
}



export async function GetLiquidityFigures(parameters: LiquidityFiguresQuery): Promise<LiquidityFiguresDto> {
    try {
       let result = await DefaultAxiosGetRequest<LiquidityFiguresDto, LiquidityFiguresQuery>(
          `GetLiquidityFigures`, parameters
          
       );
       return result.data;
    } catch (e) {
       throw e;
    }
 }