import { LibraryBooks, List } from "@mui/icons-material";
import {
  Autocomplete,
  Button,
  CircularProgress,
  Grid,
  TableContainer,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import { DataGrid, GridCellParams, GridColDef } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import * as XLSX from "xlsx";
import { AccountingTransactionDto } from "../../api/GetAccountingTransactions";
import { GetClients } from "../../api/GetClients";
import { GetEliminations } from "../../api/GetEliminations";
import {
  GetTrialBalance,
  TrialBalanceAccountDto,
} from "../../api/GetTrialBalance";
import DashboardCard from "../../components/DashboardCard";

interface Entity {
  id: string;
  name: string;
}

interface ClientOption {
  id: string;
  name: string;
  isGroup: boolean;
  optionGroup: string;
}

interface Project {
  id: string;
  name: string;
  firstName: string;
  lastName: string;
  email: string;
  amount: number;
  startDate: Date;
  closingDate: Date;
}

interface Account {
  id: string;
  name: string;
  accountType: string;
}

export default function TrialBalance() {
  const items: ClientOption[] = [
    {
      id: "Grieg Kapital konsern",
      name: "Grieg Kapital konsern",
      isGroup: true,
      optionGroup: "Konsern",
    },
    {
      id: "Grieg Logistics konsern",
      name: "Grieg Logistics konsern",
      isGroup: true,
      optionGroup: "Konsern",
    },
    {
      id: "Grieg Aqua konsern",
      name: "Grieg Aqua konsern",
      isGroup: true,
      optionGroup: "Konsern",
    },
    {
      id: "Rensefiskgruppen konsern",
      name: "Rensefiskgruppen konsern",
      isGroup: true,
      optionGroup: "Konsern",
    },
    {
      id: "Grieghallen Parkering konsern",
      name: "Grieghallen Parkering konsern",
      isGroup: true,
      optionGroup: "Konsern",
    },
  ];

  const [exportState, setExportState] = useState<"accounts" | "groups">(
    "accounts"
  );
  const [loading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<TrialBalanceAccountDto[]>([]);
  const [clients, setClients] = useState<ClientOption[]>([]);
  const [clientsFromGroup, setClientsFromGroup] = useState<string[]>([]);
  const [selectedItem, setSelectedItem] = useState<ClientOption | null>(null);
  const [accounts, setAccounts] = useState<Account[]>([]);
  const [accountTypes, setAccountTypes] = useState<string[]>([]);
  const [eliminationTransactions, setEliminationTransactions] = useState<
    AccountingTransactionDto[]
  >([]);
  const [columns, setColumns] = useState<GridColDef<Account>[]>([
    {
      field: "id",
      headerName: "Account Id",
      width: 100,
      editable: true,
    },
    {
      field: "name",
      headerName: "Account Name",
      width: 500,
      editable: false,
    },
    {
      field: "accountType",
      headerName: "Type",
      width: 500,
      editable: false,
    },
  ]);

  useEffect(() => {
    loadClients();
    async function loadClients() {
      let data = await GetClients();
      setClients(
        data.map((item) => ({
          id: item.name,
          name: item.name,
          isGroup: false,
          optionGroup: "Klienter",
        }))
      );
    }
  }, []);

  useEffect(() => {
    try {
      if (!selectedItem) return;
      if (selectedItem.isGroup) {
        getClients(selectedItem.id);
      } else {
        loadData();
        getColumns();
      }
    } catch (e) {
      alert(e);
    }
    async function getClients(group: string) {
      let result = await GetClients([group]);
      setClientsFromGroup(result.map((item) => item.name));
    }
  }, [selectedItem]);

  useEffect(() => {
    if (!data || data.length === 0) return;

    const accounts = data.reduce(
      (items: Account[], item: TrialBalanceAccountDto) => {
        if (items.map((c) => c.id).includes(item.accountId)) return items;
        items.push({
          id: item.accountId,
          name: item.accountName,
          accountType: item.accountType,
        });
        return items;
      },
      []
    );
    accounts.sort((a, b) => parseInt(a.id) - parseInt(b.id));

    const accountTypes = accounts.reduce((items: string[], item: Account) => {
      if (items.includes(item.accountType)) return items;
      items.push(item.accountType);
      return items;
    }, []);

    setAccountTypes(accountTypes);

    getColumns();
    setAccounts(accounts);
  }, [data]);

  useEffect(() => {
    if (!clientsFromGroup || clientsFromGroup.length === 0 || !selectedItem)
      return;
    loadData();
    getColumns();
  }, [clientsFromGroup]);

  useEffect(() => {
    getColumns();
  }, [exportState]);

  async function loadData() {
    try {
      if (!selectedItem) return;
      setLoading(true);

      let data = await GetTrialBalance({
        periodFrom: "202401",
        periodTo: "202410",
        clients: selectedItem?.isGroup ? clientsFromGroup : [selectedItem.id],
        aggregateTimePeriods: true,
      });

      let elims: AccountingTransactionDto[] = [];
      if (selectedItem.isGroup) {
        elims = await GetEliminations({
          periodFrom: "202401",
          periodTo: "202410",
          clients: clientsFromGroup,
        });
      }

      setData(data);
      setEliminationTransactions(elims);
      setLoading(false);
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  }

  function getColumns() {
    const cols: GridColDef<Account>[] = [
      {
        field: "id",
        headerName: "Account Id",
        width: 75,
        editable: true,
      },
      {
        field: "name",
        headerName: "Account Name",
        flex: 1,
        editable: false,
      },
      {
        field: "accountType",
        headerName: "Type",
        width: 200,
        flex: clients.length > 5 ? 0 : 1,
        editable: false,
      },
    ];

    if (!selectedItem) return;

    const clientCols: GridColDef<Account>[] = selectedItem?.isGroup
      ? clientsFromGroup.map((item) => {
          const col: GridColDef<Account> = {
            field: item,
            headerName: item,
            align: "right",
            type: "number",
            width: 100,
            editable: false,
            renderCell: (params) =>
              data
                .filter(
                  (acc) =>
                    params.row.id === acc.accountId && item === acc.company
                )
                .reduce((value, item) => (value += item.amount), 0)
                .toLocaleString("en-US"),
          };
          return col;
        })
      : [selectedItem].map((item) => {
          const col: GridColDef<Account> = {
            field: item?.id,
            headerName: item?.name,
            align: "right",
            type: "number",
            width: 100,
            editable: false,
            renderCell: (params) =>
              data
                .filter(
                  (acc) =>
                    params.row.id === acc.accountId && item.id === acc.company
                )
                .reduce((value, item) => (value += item.amount), 0)
                .toLocaleString("en-US"),
          };
          return col;
        });

    const elimCol: GridColDef<Account> = {
      field: "Eliminations",
      headerName: "Eliminations",
      align: "right",
      width: 110,
      type: "number",
      editable: false,
      renderCell: (params) =>
        eliminationTransactions
          .filter((acc) => params.row.id === acc.accountId)
          .reduce((value, item) => (value += item.amount), 0)
          .toLocaleString("en-US"),
    };

    const summaryCol: GridColDef<Account> = {
      field: "Total",
      headerName: "Total",
      cellClassName: (params: GridCellParams<Account>) => {
        return "hightlighted-column";
      },
      align: "right",
      width: 110,
      type: "number",
      editable: false,
      renderCell: (params) => {
        let amount = data
          .filter((acc) => params.row.id === acc.accountId)
          .reduce((value, item) => (value += item.amount), 0);

        amount += eliminationTransactions
          .filter((acc) => params.row.id === acc.accountId)
          .reduce((value, item) => (value += item.amount), 0);

        return amount.toLocaleString("en-US");
      },
    };

    if (selectedItem.isGroup) {
      setColumns([...cols, summaryCol, ...clientCols, elimCol]);
    } else {
      setColumns([...cols, ...clientCols]);
    }
  }

  function createDataSet() {
    let dataSet = [];

    if (exportState === "accounts") {
      dataSet = accounts.map((item) => {
        let obj: any = {
          AccountId: item.id,
          AccountName: item.name,
          AccountType: item.accountType,
        };
        clientsFromGroup.forEach((client) => {
          let amount = data
            .filter(
              (acc) => obj.AccountId === acc.accountId && client === acc.company
            )
            .reduce((value, item) => (value += item.amount), 0);
          obj[client] = amount;
        });

        let total = data
          .filter((acc) => obj.AccountId === acc.accountId)
          .reduce((value, item) => (value += item.amount), 0);

        let elims = eliminationTransactions
          .filter((trans) => obj.AccountId === trans.accountId)
          .reduce((value, item) => (value += item.amount), 0);

        total += elims;

        obj["Eliminations"] = elims;
        obj["Total"] = total;
        return obj;
      });
    } else {
      dataSet = accountTypes.map((item) => {
        let obj: any = {
          AccountType: item,
        };
        clients.forEach((client) => {
          let amount = data
            .filter(
              (acc) =>
                obj.AccountType === acc.accountType && client.id === acc.company
            )
            .reduce((value, item) => (value += item.amount), 0);
          obj[client.id] = amount;
        });

        let total = data
          .filter((acc) => obj.AccountType === acc.accountType)
          .reduce((value, item) => (value += item.amount), 0);

        let elims = eliminationTransactions
          .filter((trans) => obj.AccountType === trans.accountType)
          .reduce((value, item) => (value += item.amount), 0);

        total += elims;

        obj["Eliminations"] = elims;
        obj["Total"] = total;
        return obj;
      });
    }

    return dataSet;
  }

  function onExportToExcel() {
    let dataSet = createDataSet();
    const ws = XLSX.utils.json_to_sheet(dataSet);
    const elimWs = XLSX.utils.json_to_sheet(eliminationTransactions);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, ws, "Data");
    XLSX.utils.book_append_sheet(workbook, elimWs, "Eliminations");
    XLSX.writeFile(workbook, "Data.xlsx");
  }

  return (
    <DashboardCard
      size="large"
      title="Saldobalanse"
      component={
        <Grid item container xs={12} spacing={3}>
          <Grid item container xs={12} spacing={3}>
            <Grid item sx={{ width: 300 }}>
              <Autocomplete
                size="small"
                sx={{
                  backgroundColor: "primary.contrastText",
                  ".MuiInputBase-root": { fontSize: 11 },
                }}
                ListboxProps={{ sx: { fontSize: 11 } }}
                options={[...items, ...clients].map((item) => ({
                  ...item,
                  group: item.isGroup ? "Group" : "Client",
                }))}
                groupBy={(option) => option.optionGroup}
                value={selectedItem}
                getOptionLabel={(option) => option.name}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                onChange={(event, value) => setSelectedItem(value)}
                renderInput={(params) => (
                  <TextField
                    sx={{
                      fontSize: 11,
                    }}
                    {...params}
                    InputLabelProps={{ sx: { fontSize: 11 } }}
                    label="Gruppe"
                  />
                )}
              />
            </Grid>
            <Grid item>
              <Button
                size="small"
                variant="contained"
                onClick={(e) => onExportToExcel()}
              >
                EXPORT
              </Button>
            </Grid>
            <Grid item>
              <ToggleButtonGroup
                exclusive
                onChange={(e, newAlignment) => {
                  if (newAlignment !== null) setExportState(newAlignment);
                }}
                value={exportState}
              >
                <ToggleButton size="small" value="accounts">
                  <LibraryBooks fontSize="small" />
                </ToggleButton>

                <ToggleButton value="groups">
                  <List fontSize="small" />
                </ToggleButton>
              </ToggleButtonGroup>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <TableContainer style={{ width: "80vw", height: "75vh" }}>
              {loading ? (
                <Grid
                  item
                  container
                  xs={12}
                  justifyContent="center"
                  alignContent="center"
                >
                  <Grid item>
                    <CircularProgress size={40} />
                  </Grid>
                </Grid>
              ) : (
                <DataGrid
                  sx={{
                    ".MuiDataGrid-columnHeader": {
                      color: "white",
                      backgroundColor: "primary.main",
                    },
                    "& .hightlighted-column": {
                      backgroundColor: "lightgrey",
                      fontWeight: "bold",
                    },
                    fontSize: 11,
                    "& svg": {
                      color: "white",
                    },
                  }}
                  rows={accounts}
                  getRowId={(row: Account) => row.id}
                  rowHeight={14}
                  columns={columns}
                  initialState={{
                    sorting: {
                      sortModel: [{ field: "accountId", sort: "asc" }],
                    },
                  }}
                  disableRowSelectionOnClick
                />
              )}
            </TableContainer>
          </Grid>
        </Grid>
      }
    />
  );
}
