import { ReportingEntity } from "../types/KeyMetrics";
import { DefaultAxiosGetRequest } from "./ApiBase";

export async function GetReportingEntities(): Promise<ReportingEntity[]> {
    try {
       let result = await DefaultAxiosGetRequest<ReportingEntity[], undefined>(
          `GetReportingEntities`, undefined
          
       );

       return result.data;
    } catch (e) {
       throw e;
    }
 }