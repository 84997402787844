import React, { useState } from "react";
import {
  Box,
  Slider,
  Button,
  Typography,
  Grid,
  IconButton,
  Tooltip,
} from "@mui/material";
import CreatePowerpoint from "../api/CreatePowerpoint";
import { AccountingSet } from "../types/account";
import { Download } from "@mui/icons-material";
import { get } from "http";

export type SearchCriteriaParameters = {
  startPeriod: string;
  endPeriod: string;
};

export type SearchCriteriaProps = {
  data: AccountingSet | undefined;
  onSearch: (parameters: SearchCriteriaParameters) => void;
  client: string;
};

function getMaxValueBasedOnToday(): number {
  const today = new Date();
  const year = today.getFullYear();
  const month = today.getMonth(); // getMonth() returns 0-11, so add 1 to get 1-12

  // Format the year and month into the desired number format
  const maxValue = year * 12 + month - 1;

  return maxValue;
}

const getYearsArray = (startYear: number): number[] => {
  const currentYear = new Date().getFullYear();
  const years = [];
  for (let year = startYear; year <= currentYear; year++) {
    years.push(year);
  }
  return years;
};

// Helper to format the slider value into year-month format
const formatPeriod = (value: number): string => {
  const year = Math.floor(value / 12);
  const month = (value % 12) + 1; // Adjust for 0-based months
  const formattedMonth = month < 10 ? `0${month}` : month;
  return `${year}${formattedMonth}`;
};

const getFirstPeriodOfCurrentYear = (): number => {
  const lastPeriod = getMaxValueBasedOnToday();
  const year = parseInt(formatPeriod(lastPeriod).toString().substring(0, 4));
  return year * 12 + 0; // January of the year
};

// Define min and max values based on 202101 to 202412
const minStandardValue = 2021 * 12 + 0; // 202101 (January)
const maxStandardValue = getMaxValueBasedOnToday();

const SearchCriteria = ({ data, onSearch, client }: SearchCriteriaProps) => {
  const [periodRange, setPeriodRange] = useState<number[]>([
    getFirstPeriodOfCurrentYear(),
    getMaxValueBasedOnToday(),
  ]);

  const [minValue, setMinValue] = useState(getFirstPeriodOfCurrentYear());
  const [maxValue, setMaxValue] = useState(getMaxValueBasedOnToday);
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());

  // Handle slider change
  const handleSliderChange = (event: Event, newValue: number | number[]) => {
    setPeriodRange(newValue as number[]);
  };

  // Handle the Apply button click
  const handleApply = () => {
    const formattedStartPeriod = formatPeriod(periodRange[0]);
    const formattedEndPeriod = formatPeriod(periodRange[1]);
    onSearch({
      startPeriod: formattedStartPeriod,
      endPeriod: formattedEndPeriod,
    });
  };

  // Handle the PowerPoint button click
  const handlePowerPoint = () => {
    if (data) CreatePowerpoint(data);
  };

  // Handle year button click
  const handleYearClick = (year: number) => {
    const currentYear = new Date().getFullYear();

    const startOfYear = (year - 2021) * 12 + minStandardValue; // January of the year
    let endOfYear =
      year === currentYear ? getMaxValueBasedOnToday() : startOfYear + 11; // December of the year

    setSelectedYear(year);
    setPeriodRange([startOfYear, endOfYear]);
    setMinValue(startOfYear);
    setMaxValue(endOfYear);
  };

  return (
    <Grid item container xs={12}>
      <Grid item xs={12}>
        <Typography variant="h6">Periode</Typography>
      </Grid>
      <Grid item container xs={12} justifyContent={"left"} sx={{ mt: 4 }}>
        <Grid item xs={12} md={6}>
          {/* Range Slider */}
          <Slider
            value={periodRange}
            min={minValue}
            max={maxValue}
            step={1}
            onChange={handleSliderChange}
            valueLabelDisplay="on"
            valueLabelFormat={(value) => formatPeriod(value)}
          />
        </Grid>
      </Grid>
      {/* Buttons for each year */}
      <Grid container spacing={1} sx={{ marginBottom: 2 }}>
        {getYearsArray(2021).map((year) => (
          <Grid item key={year}>
            <Button
              variant={selectedYear === year ? "contained" : "outlined"}
              onClick={() => handleYearClick(year)}
            >
              {year}
            </Button>
          </Grid>
        ))}
      </Grid>

      {/* Buttons */}
      <Grid item container xs={12} justifyContent={"space-between"}>
        <Grid item>
          <Button variant="contained" color="primary" onClick={handleApply}>
            Søk
          </Button>
        </Grid>
        <Grid>
          <Tooltip title="Generer Powerpoint" placement="top">
            <span>
              <IconButton
                disabled={data === undefined}
                onClick={handlePowerPoint}
                sx={{ marginLeft: 2 }}
              >
                <Download />
              </IconButton>
            </span>
          </Tooltip>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default SearchCriteria;
