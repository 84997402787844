import { KeyMetricValue } from "../types/KeyMetrics";
import { DefaultAxiosGetRequest } from "./ApiBase";

type KeyMetricValuesQuery = {
    reportingEntityId?: number;
    reportingPeriod?: string;
}

export async function GetKeyMetricValues(parameters: KeyMetricValuesQuery): Promise<KeyMetricValue[]> {
    try {
       let result = await DefaultAxiosGetRequest<KeyMetricValue[], KeyMetricValuesQuery>(
          `GetKeyMetricValues`, parameters
          
       );
       return result.data;
    } catch (e) {
       throw e;
    }
 }