import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { useEffect, useState } from "react";
import {
  AccountClass,
  AccountType,
  GetAccountingStructure,
  TrialBalanceAccountDto,
} from "../../api/GetTrialBalance";
import { formatNumberInThousands } from "../../utils/formatting";
import {
  calculateAmountByClientAndAccount,
  calculateAmountByClientAndAccountType,
  calculateAmountByPeriodAndAccount,
  calculateAmountByPeriodAndAccountType,
  calculatePretaxIncomeByPeriod,
  getDistinctAccountsByCaption,
  hasValuesForAccountTypeOrCaption,
} from "../../utils/trialBalanceCalculations";
import SkeletonTable from "../SkeletonTable";

const grossProfit = ["Driftsinntekter", "Varekostnad"];
const EBITDA = [
  "Driftsinntekter",
  "Varekostnad",
  "Lønnskostnad",
  "Annen driftskostnad",
];
const EBIT = [
  "Driftsinntekter",
  "Varekostnad",
  "Lønnskostnad",
  "Annen driftskostnad",
  "Avskrivninger",
];

type ReportingTableProps = {
  periods: string[];
  clients: string[];
  data: TrialBalanceAccountDto[];
  comparisonData: TrialBalanceAccountDto[];
  showColumnsAs: "periods" | "clients";
  type: "balance" | "profit";
  language: "no" | "en";
  showSummaryLines?: boolean;
  showHeaders?: boolean;
  showEBITDA?: boolean;
  showEBIT?: boolean;
  showGrossProfit?: boolean;
};

export function ReportingTable({
  periods,
  clients,
  data,
  comparisonData,
  showColumnsAs,
  language = "no",
  type,
  showSummaryLines = true,
  showHeaders = true,
  showEBIT = true,
  showEBITDA = true,
  showGrossProfit = true,
}: ReportingTableProps) {
  const [loading, setLoading] = useState<boolean>(false);
  const [structure, setStructure] = useState<AccountType[]>([]);
  const [selectedItem, setSelectedItem] = useState<AccountClass | null>(null);

  const highligtColor = "#faf7f2";

  useEffect(() => {
    const loadData = async () => {
      try {
        setLoading(true);
        const data = await GetAccountingStructure();
        setStructure(data);
      } catch (e) {
        console.log(e);
      } finally {
        setLoading(false);
      }
    };
    loadData();
  }, []);

  const onClickRow = (item: AccountClass) => {
    setSelectedItem((state) => (state === item ? null : item));
  };

  return (
    <Grid item container xs={12}>
      <Grid item xs={12}>
        {loading ? (
          <SkeletonTable rows={6} cols={5} />
        ) : (
          <Grid item xs={12}>
            <TableContainer component={Paper} sx={{ padding: "12px" }}>
              <Table
                sx={{
                  minWidth: 650,
                  fontFamily: "Arial, sans-serif",
                  tr: { fontFamily: "Poppins, sans-serif", fontSize: "0.9rem" },
                }}
              >
                <TableHead>
                  <TableRow
                    sx={{
                      th: {
                        fontWeight: "bold",
                        fontFamily: "Poppins, sans-serif",
                        fontSize: "0.8rem",
                        padding: "10px",
                      },
                    }}
                  >
                    <TableCell>TNOK</TableCell>
                    {(showColumnsAs === "periods" ? periods : clients).map(
                      (item) => (
                        <TableCell width={125} align="right">
                          {item}
                        </TableCell>
                      )
                    )}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {structure
                    .filter((accountType) =>
                      hasValuesForAccountTypeOrCaption(
                        showColumnsAs === "periods"
                          ? [...data, ...comparisonData]
                          : data,
                        accountType.name
                      )
                    )
                    .map((accountType) => (
                      <>
                        {showHeaders && (
                          <TableRow
                            sx={{
                              td: {
                                fontWeight: "bold",
                                fontFamily: "Poppins, sans-serif",
                                fontSize: "0.8rem",
                                padding: "10px",
                                paddingTop: "25px",
                                borderBottom: "none",
                              },
                            }}
                          >
                            <TableCell colSpan={periods.length + 1}>
                              {language === "no"
                                ? accountType.name
                                : accountType.nameEnglish}
                            </TableCell>
                          </TableRow>
                        )}

                        {accountType.accountClasses
                          .filter((accountClass) =>
                            hasValuesForAccountTypeOrCaption(
                              showColumnsAs === "periods"
                                ? [...data, ...comparisonData]
                                : data,
                              accountClass.name
                            )
                          )
                          .map((item) => (
                            <>
                              <TableRow
                                onClick={() => onClickRow(item)}
                                sx={{
                                  width: "100%",
                                  "&:hover": {
                                    cursor: "pointer",
                                    backgroundColor: "lightgray",
                                  },
                                  td: {
                                    fontFamily: "Poppins, sans-serif",
                                    fontSize: "0.8rem",
                                    padding: "10px",
                                    borderBottom:
                                      selectedItem === item
                                        ? "1x solid lightgray"
                                        : "none",
                                  },
                                }}
                              >
                                <TableCell>
                                  {language === "no"
                                    ? item.name
                                    : item.nameEnglish}
                                </TableCell>
                                {(showColumnsAs === "periods"
                                  ? periods
                                  : clients
                                ).map((col, index) => (
                                  <TableCell align="right">
                                    {formatNumberInThousands(
                                      showColumnsAs === "periods"
                                        ? calculateAmountByPeriodAndAccount(
                                            [...data, ...comparisonData],
                                            col,
                                            [item.name],
                                            type
                                          )
                                        : calculateAmountByClientAndAccount(
                                            data,
                                            col,
                                            [item.name],
                                            type
                                          )
                                    )}
                                  </TableCell>
                                ))}
                              </TableRow>
                              {showGrossProfit &&
                                item.name === "Varekostnad" && (
                                  <TableRow
                                    sx={{
                                      td: {
                                        fontWeight: "bold",
                                        backgroundColor: highligtColor,
                                        fontFamily: "Poppins, sans-serif",
                                        fontSize: "0.8rem",
                                        padding: "10px",
                                        borderBottom:
                                          selectedItem === item
                                            ? "1x solid lightgray"
                                            : "none",
                                      },
                                    }}
                                  >
                                    <TableCell>
                                      {language === "no"
                                        ? "Bruttofortjeneste"
                                        : "Gross profit"}
                                    </TableCell>
                                    {(showColumnsAs === "periods"
                                      ? periods
                                      : clients
                                    ).map((col, index) => (
                                      <TableCell align="right">
                                        {formatNumberInThousands(
                                          showColumnsAs === "periods"
                                            ? calculateAmountByPeriodAndAccount(
                                                [...data, ...comparisonData],
                                                col,
                                                grossProfit,
                                                type,
                                                true
                                              )
                                            : calculateAmountByClientAndAccount(
                                                data,
                                                col,
                                                grossProfit,
                                                type,
                                                true
                                              )
                                        )}
                                      </TableCell>
                                    ))}
                                  </TableRow>
                                )}
                              {showEBITDA &&
                                item.name === "Annen driftskostnad" && (
                                  <TableRow
                                    sx={{
                                      td: {
                                        fontWeight: "bold",
                                        backgroundColor: highligtColor,
                                        fontFamily: "Poppins, sans-serif",
                                        fontSize: "0.8rem",
                                        padding: "10px",
                                        borderBottom:
                                          selectedItem === item
                                            ? "1x solid lightgray"
                                            : "none",
                                      },
                                    }}
                                  >
                                    <TableCell>EBITDA</TableCell>
                                    {(showColumnsAs === "periods"
                                      ? periods
                                      : clients
                                    ).map((col, index) => (
                                      <TableCell align="right">
                                        {formatNumberInThousands(
                                          showColumnsAs === "periods"
                                            ? calculateAmountByPeriodAndAccount(
                                                [...data, ...comparisonData],
                                                col,
                                                EBITDA,
                                                type,
                                                true
                                              )
                                            : calculateAmountByClientAndAccount(
                                                data,
                                                col,
                                                EBITDA,
                                                type,
                                                true
                                              )
                                        )}
                                      </TableCell>
                                    ))}
                                  </TableRow>
                                )}
                              {showEBIT && item.name === "Avskrivninger" && (
                                <TableRow
                                  sx={{
                                    td: {
                                      fontWeight: "bold",
                                      backgroundColor: highligtColor,
                                      fontFamily: "Poppins, sans-serif",
                                      fontSize: "0.8rem",
                                      padding: "10px",
                                      borderBottom:
                                        selectedItem === item
                                          ? "1x solid lightgray"
                                          : "none",
                                    },
                                  }}
                                >
                                  <TableCell>EBIT</TableCell>
                                  {(showColumnsAs === "periods"
                                    ? periods
                                    : clients
                                  ).map((col, index) => (
                                    <TableCell align="right">
                                      {formatNumberInThousands(
                                        showColumnsAs === "periods"
                                          ? calculateAmountByPeriodAndAccount(
                                              [...data, ...comparisonData],
                                              col,
                                              EBIT,
                                              type,
                                              true
                                            )
                                          : calculateAmountByClientAndAccount(
                                              data,
                                              col,
                                              EBIT,
                                              type,
                                              true
                                            )
                                      )}
                                    </TableCell>
                                  ))}
                                </TableRow>
                              )}
                              {selectedItem &&
                                selectedItem === item &&
                                getDistinctAccountsByCaption(
                                  showColumnsAs === "periods"
                                    ? [...data, ...comparisonData]
                                    : data,
                                  selectedItem.name
                                ).map((item) => (
                                  <TableRow
                                    sx={{
                                      width: "100%",
                                      td: {
                                        fontFamily: "Poppins, sans-serif",
                                        fontSize: "0.8rem",
                                        padding: "10px",
                                        borderBottom: "none",
                                        fontStyle: "italic",
                                      },
                                    }}
                                  >
                                    <TableCell>
                                      {"   " +
                                        item.accountId +
                                        " " +
                                        item.accountName}
                                    </TableCell>
                                    {(showColumnsAs === "periods"
                                      ? periods
                                      : clients
                                    ).map((col, index) => (
                                      <TableCell align="right">
                                        {formatNumberInThousands(
                                          showColumnsAs === "periods"
                                            ? calculateAmountByPeriodAndAccount(
                                                [...data, ...comparisonData],
                                                col,
                                                [item.accountId],
                                                type
                                              )
                                            : calculateAmountByClientAndAccount(
                                                data,
                                                col,
                                                [item.accountId],
                                                type
                                              )
                                        )}
                                      </TableCell>
                                    ))}
                                  </TableRow>
                                ))}
                            </>
                          ))}
                        {showSummaryLines && (
                          <TableRow
                            sx={{
                              td: {
                                fontFamily: "Poppins, sans-serif",
                                fontSize: "0.8rem",
                                padding: "10px",
                                borderBottom: "none",
                                fontWeight: "bold",
                                backgroundColor: highligtColor,
                              },
                            }}
                          >
                            <TableCell>{`Sum ${
                              language === "no"
                                ? accountType.name.toLowerCase()
                                : accountType.nameEnglish.toLowerCase()
                            }`}</TableCell>
                            {(showColumnsAs === "periods"
                              ? periods
                              : clients
                            ).map((col) => (
                              <TableCell align="right">
                                {formatNumberInThousands(
                                  showColumnsAs === "periods"
                                    ? calculateAmountByPeriodAndAccountType(
                                        [...data, ...comparisonData],
                                        col,
                                        [accountType.name],
                                        type
                                      )
                                    : calculateAmountByClientAndAccountType(
                                        data,
                                        col,
                                        [accountType.name],
                                        type
                                      )
                                )}
                              </TableCell>
                            ))}
                          </TableRow>
                        )}
                      </>
                    ))}
                  {type === "profit" && data.length > 0 && (
                    <TableRow
                      sx={{
                        td: {
                          fontFamily: "Poppins, sans-serif",
                          fontSize: "0.8rem",
                          padding: "10px",
                          borderBottom: "none",
                          fontWeight: "bold",
                          backgroundColor: highligtColor,
                        },
                      }}
                    >
                      <TableCell>
                        {language === "no"
                          ? "Resultat før skatt"
                          : "Pretax income"}
                      </TableCell>
                      {(showColumnsAs === "periods" ? periods : clients).map(
                        (col) => (
                          <TableCell align="right">
                            {formatNumberInThousands(
                              showColumnsAs === "periods"
                                ? calculatePretaxIncomeByPeriod(
                                    [...data, ...comparisonData],
                                    col
                                  )
                                : calculatePretaxIncomeByPeriod(
                                    [...data, ...comparisonData],
                                    col
                                  )
                            )}
                          </TableCell>
                        )
                      )}
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
}
